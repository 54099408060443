import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import styles from 'assets/styles/recipients.module.scss';
import cx from 'classnames';
import RecipientsList from './RecipientsList';
import selectState from 'store/selectors/recipients';
import { Dialog, Icon } from 'components/common';
import AddRecipient from './AddRecipient';
import DialogV2 from 'components/common/DialogV2';

/**
 * @description Recipients page loads the list of recipients and the add new recipient form
 * @param {object} props
 * @returns {JSX}
 */

const Recipients = () => {
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showAddRecipient, setShowAddRecipient] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);

    // Hack to trigger refresh recipients from parent because of current implementation.
    // TODO: Refactor to make it cleaner
    const [refreshTrigger, setRefreshTrigger] = useState(false);

    const { loading, error, recipients, addRecipientSuccess } = selectState();

    const handleBeneficiaryClick = (index) => {
        setSelectedIndex(index);
    };

    useEffect(() => {
        if (addRecipientSuccess && !isModalOpen) {
            setIsModalOpen(true);
        }
    }, [addRecipientSuccess, isModalOpen]);

    return (
        <Grid container className={cx(styles.container)}>
            <Grid item xs={12} className={cx(styles.titleWrapper)}>
                <h1 className={cx(styles.title)}>Recipients</h1>
                <Icon name={'refresh'} onClick={() => setRefreshTrigger((state) => !state)} />
            </Grid>
            <Grid container className={cx(styles.recipientBody)}>
                <RecipientsList
                    refreshTrigger={refreshTrigger}
                    recipients={recipients}
                    handleBeneficiaryClick={handleBeneficiaryClick}
                    selectedIndex={selectedIndex}
                    isEmpty={recipients?.items.length === 0}
                    isError={error}
                    isLoading={loading}
                    handleAddRecipient={() => setShowAddRecipient(true)}
                />
            </Grid>
            <Dialog
                title="Add recipient"
                className={'recipients-modal'}
                fullWidth
                maxWidth={'xl'}
                open={showAddRecipient}
                isActions={false}
                setOpen={(event, reason) => {
                    setShowCancelModal(true);
                    // setShowAddRecipient(false);
                }}
                paperProps={{
                    maxWidth: '100%',
                    width: '80%',
                }}
                onClose={() => {
                    setShowAddRecipient(false);
                }}
                backdropProps={{
                    style: {
                        backgroundColor: 'rgba(255,255,255, 0.8)',
                    },
                }}>
                <AddRecipient
                    onCancel={() => {
                        setShowCancelModal(true);
                    }}
                    onClose={() => {
                        setShowAddRecipient(false);
                        setRefreshTrigger((state) => !state);
                    }}
                />
            </Dialog>
            <DialogV2
                title={'Cancel recipient creation'}
                open={showCancelModal}
                isSingleButton={false}
                setOpen={() => setShowCancelModal(false)}
                cancelButton={'No'}
                submitButton={'Yes'}
                variant={'warning'}
                submitAction={() => {
                    setShowCancelModal(false);
                    setShowAddRecipient(false);
                }}
                cancelAction={() => setShowCancelModal(false)}>
                On clicking Yes,the recipient data will be discarded. Are you sure you want to
                cancel?
            </DialogV2>
        </Grid>
    );
};

export default Recipients;
