import { Box, Grid, List } from '@mui/material';
import styles from 'assets/styles/account.module.scss';
import cx from 'classnames';
import { CustomList, CustomTooltip, Icon } from 'components/common';
import React, { useCallback, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAuthData, getUserTypes } from 'store/selectors/auth';
import { AllowedUsers, belongsTo } from 'utils/auth';
import {
    getCurrencySymbol,
    isProd,
    formatAmount,
    isQuoteEnabled,
    accountFeatureFlag,
    disableAccountCreatedInCurrentMonth,
} from 'utils/helpers';
import { getAccountStatusIcon, getAccountStatusDescription } from './utils';
import AddFundsModal from './AddFundsModal';
import selectAppState from 'AppSelectors';
import CreateSubAccount from 'components/dashboard/account/CreateSubAccount';
import { MODAL_TYPE } from 'components/dashboard/account/constants';
import UpdateAccount from './UpdateAccount';
import CloseAccount from './CloseSubAccount';

const getSubtitle = (account) => {
    var subTitle = '';
    if (account.account_number) {
        subTitle += `Acc-number: ${account.account_number}, `;
    }
    if (account.sort_code) {
        subTitle += `Sort code: ${account.sort_code}, `;
    }
    if (account.iban) {
        subTitle += `IBAN: ${account.iban}, `;
    }
    if (account.bic) {
        subTitle += `BIC: ${account.bic}, `;
    }
    return subTitle?.substring(0, subTitle.length - 2);
};

const AccountList = ({ accounts, pageMeta }) => {
    const navigate = useNavigate();
    const { featureFlags } = selectAppState();
    const userTypes = useSelector(getUserTypes);
    const { login } = useSelector(getAuthData);
    const isValidCompany = isProd && login.company_id === '281a5449-5d06-4de5-a4f7-f3ed9ef56c93';
    const [displayModal, setDisplayModal] = useState('');
    const [accountMeta, setAccountMeta] = useState({
        accountId: '',
        currency: '',
    });

    const copyAccount = useCallback((account) => {
        var toCopy = account.account_name + '\n';
        if (account.account_number) {
            toCopy += `Account number: ${account?.account_number} `;
        }
        if (account.sort_code) {
            toCopy += `Sort code: ${account?.sort_code} `;
        }
        if (account.iban) {
            toCopy += `IBAN: ${account?.iban} `;
        }
        if (account.bic) {
            toCopy += `BIC: ${account?.bic} `;
        }
        return toCopy.trim();
    }, []);

    const onModalClose = (val = false) => {
        setDisplayModal(val);
    };

    const renderTile = (account) => {
        return (
            <div style={{ display: 'flex', gap: '17px' }}>
                <div>
                    {account.account_name}
                    {account.client_account_name
                        ? ` (Nickname: ${account.client_account_name})`
                        : ''}
                </div>
                {account.type === 'main_account' && <div className={cx(styles.typeTag)}>Main</div>}
            </div>
        );
    };

    return (
        <Grid item md={12} xs={12} className={cx(styles.list)} style={{ marginTop: '56px' }}>
            <List>
                {accounts?.length > 0 &&
                    accounts.map((account) => (
                        <React.Fragment key={account.id}>
                            <CustomList
                                id={account.id}
                                key={account.id}
                                name={account.client_account_name || account.account_name}
                                title={renderTile(account)}
                                subtitle={getSubtitle(account)}
                                avatar={'true'}
                                customClass={cx(styles.item, styles.subItem)}
                                avatarClass={cx(
                                    styles.avatar,
                                    account.status === 'blocked' && styles.avatarDisable
                                )}
                                subAvatar={
                                    <Box className={cx(styles.avatarSubIcon)}>
                                        <CustomTooltip
                                            info={getAccountStatusDescription(account.status)}>
                                            <Icon name={getAccountStatusIcon(account.status)} />
                                        </CustomTooltip>
                                    </Box>
                                }
                                actions={[
                                    <div key={'actions'} className={styles.actions}>
                                        <div className={cx(styles.accountBalance)}>
                                            <NumberFormat
                                                prefix={getCurrencySymbol(account.currency)}
                                                value={formatAmount(account.balance)}
                                                thousandSeparator={true}
                                                displayType={'text'}
                                                decimalScale={2}
                                            />
                                        </div>
                                        <div
                                            className={cx(styles.actionsIcon)}
                                            disabled={account.status === 'in-progress'} // Enable view transactions for other than pending accounts
                                            onClick={() =>
                                                navigate({
                                                    pathname: `/dashboard/accounts/${account.id}/transactions`,
                                                })
                                            }>
                                            <CustomTooltip info={'View Transactions'}>
                                                <Icon name={'transactions'} />
                                            </CustomTooltip>
                                        </div>
                                        <div
                                            className={cx(styles.actionsIcon)}
                                            disabled={
                                                !belongsTo(userTypes, AllowedUsers.SendFunds) ||
                                                account.status !== 'active' ||
                                                parseFloat(account.balance) === 0
                                            }
                                            onClick={() =>
                                                navigate({
                                                    pathname: `/dashboard/funds`,
                                                    search: `?from=${account.id}`,
                                                })
                                            }>
                                            <CustomTooltip info={'Send funds'}>
                                                <Icon
                                                    name={
                                                        isQuoteEnabled(account)
                                                            ? 'send-exchange'
                                                            : 'send'
                                                    }
                                                />
                                            </CustomTooltip>
                                        </div>
                                        <div
                                            className={cx(styles.actionsIcon)}
                                            disabled={account.status === 'in-progress'}
                                            onClick={() =>
                                                navigate({
                                                    pathname: `/dashboard/pending-payments`,
                                                    search: `?search_account=${
                                                        account.iban ?? account.account_number
                                                    }&currency=${account.currency}`,
                                                })
                                            }>
                                            <CustomTooltip info={'Pending payments'}>
                                                <Icon name={'approve-payments'} />
                                            </CustomTooltip>
                                        </div>
                                        <div
                                            className={cx(styles.actionsIcon)}
                                            disabled={
                                                !belongsTo(
                                                    userTypes,
                                                    AllowedUsers.MakePaymentRequest
                                                ) ||
                                                account.status !== 'active' ||
                                                parseFloat(account.balance) === 0
                                            }
                                            onClick={() =>
                                                navigate({
                                                    pathname: `/dashboard/payment-request`,
                                                    search: `?from=${account.id}`,
                                                })
                                            }>
                                            <CustomTooltip info={'Queue payments'}>
                                                <Icon name={'payment-request'} />
                                            </CustomTooltip>
                                        </div>
                                        <div
                                            className={cx(styles.actionsIcon)}
                                            disabled={
                                                !belongsTo(userTypes, AllowedUsers.Reports) ||
                                                account.status === 'in-progress'
                                            }
                                            onClick={() =>
                                                navigate({
                                                    pathname: `/dashboard/transaction-report`,
                                                    search: `?from=${account.id}`,
                                                })
                                            }>
                                            <CustomTooltip info={'Transaction reports'}>
                                                <Icon name={'reports-icon'} />
                                            </CustomTooltip>
                                        </div>
                                        {(isValidCompany || !isProd) &&
                                            featureFlags.monthly_account_statement_enabled && (
                                                <div
                                                    className={cx(styles.actionsIcon)}
                                                    disabled={
                                                        !belongsTo(
                                                            userTypes,
                                                            AllowedUsers.Reports
                                                        ) ||
                                                        account.status === 'in-progress' ||
                                                        disableAccountCreatedInCurrentMonth(account)
                                                    }
                                                    onClick={() =>
                                                        navigate({
                                                            pathname: `/dashboard/account-statement`,
                                                            search: `?from=${account.id}`,
                                                        })
                                                    }>
                                                    <CustomTooltip
                                                        info={'Monthly account statement'}>
                                                        <Icon name={'monthly-statement'} />
                                                    </CustomTooltip>
                                                </div>
                                            )}
                                        <div
                                            className={cx(styles.actionsIcon)}
                                            disabled={account.status === 'in-progress'}
                                            onClick={() => {
                                                navigator.clipboard.writeText(copyAccount(account));
                                            }}>
                                            <CustomTooltip info={'Copy bank account details'}>
                                                <Icon
                                                    name={'copy'}
                                                    className={cx(styles.copyIcon)}
                                                />
                                            </CustomTooltip>
                                        </div>
                                        {!isProd && (
                                            <div
                                                className={cx(styles.actionsIconSandbox)}
                                                onClick={() => {
                                                    setDisplayModal(MODAL_TYPE.AddFunds);
                                                    setAccountMeta({
                                                        accountId: account.id,
                                                        currency: account.currency,
                                                    });
                                                }}
                                                disabled={
                                                    !belongsTo(userTypes, AllowedUsers.AddFunds) ||
                                                    account.status !== 'active'
                                                }>
                                                <CustomTooltip info={'Add Funds'}>
                                                    <Icon name={'add-funds'} />
                                                </CustomTooltip>
                                            </div>
                                        )}
                                        {featureFlags.create_account_enabled &&
                                            accountFeatureFlag(
                                                account,
                                                'linked_account_creation_enabled',
                                                true
                                            ) && (
                                                <div
                                                    className={cx(styles.actionsIcon)}
                                                    onClick={() => {
                                                        setDisplayModal(
                                                            MODAL_TYPE.CreateSubAccount
                                                        );
                                                        setAccountMeta({
                                                            currency: account.currency,
                                                            accountId: account.id,
                                                            accountName: account.account_name,
                                                        });
                                                    }}
                                                    disabled={
                                                        !belongsTo(
                                                            userTypes,
                                                            AllowedUsers.AccountAdmin
                                                        ) || account.status !== 'active'
                                                    }>
                                                    <CustomTooltip info={'Create account'}>
                                                        <Icon name={MODAL_TYPE.CreateSubAccount} />
                                                    </CustomTooltip>
                                                </div>
                                            )}
                                        {featureFlags.update_account_enabled && (
                                            <div
                                                className={cx(styles.actionsIcon)}
                                                onClick={() => {
                                                    setDisplayModal(MODAL_TYPE.UpdateAccount);
                                                    setAccountMeta({
                                                        accountId: account.id,
                                                        accountName:
                                                            account.client_account_name ||
                                                            account.account_name,
                                                    });
                                                }}
                                                disabled={
                                                    !belongsTo(
                                                        userTypes,
                                                        AllowedUsers.AccountAdmin
                                                    ) || account.status !== 'active'
                                                }>
                                                <CustomTooltip info={'Update account'}>
                                                    <Icon name={MODAL_TYPE.UpdateAccount} />
                                                </CustomTooltip>
                                            </div>
                                        )}
                                        {featureFlags.close_account_enabled &&
                                            accountFeatureFlag(
                                                account,
                                                'account_closure_enabled',
                                                true
                                            ) && (
                                                <div
                                                    className={cx(styles.actionsIconWarning)}
                                                    onClick={() => {
                                                        setDisplayModal(MODAL_TYPE.CloseAccount);
                                                        setAccountMeta({
                                                            accountId: account.id,
                                                            accountName:
                                                                account.client_account_name ||
                                                                account.account_name,
                                                            balance: account.balance,
                                                        });
                                                    }}
                                                    disabled={
                                                        !belongsTo(
                                                            userTypes,
                                                            AllowedUsers.AccountAdmin
                                                        ) || account.status !== 'active'
                                                    }>
                                                    <CustomTooltip info={'Close account'}>
                                                        <Icon name={MODAL_TYPE.CloseAccount} />
                                                    </CustomTooltip>
                                                </div>
                                            )}
                                    </div>,
                                ]}
                            />
                        </React.Fragment>
                    ))}
            </List>
            <AddFundsModal
                displayModal={displayModal === MODAL_TYPE.AddFunds}
                accountMeta={accountMeta}
                handleModal={onModalClose}
            />
            {featureFlags.create_account_enabled && (
                <CreateSubAccount
                    displayModal={displayModal === MODAL_TYPE.CreateSubAccount}
                    accountMeta={accountMeta}
                    handleModal={onModalClose}
                    pageType="accountList"
                    pageMeta={pageMeta}
                />
            )}
            {featureFlags.update_account_enabled && (
                <UpdateAccount
                    displayModal={displayModal === MODAL_TYPE.UpdateAccount}
                    accountMeta={accountMeta}
                    handleModal={onModalClose}
                    pageType="accountList"
                    pageMeta={pageMeta}
                />
            )}
            {featureFlags.close_account_enabled && (
                <CloseAccount
                    displayModal={displayModal === MODAL_TYPE.CloseAccount}
                    accountMeta={accountMeta}
                    handleModal={onModalClose}
                    pageType="accountList"
                    pageMeta={pageMeta}
                />
            )}
        </Grid>
    );
};
export default AccountList;
