import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import cx from 'classnames';
import * as yup from 'yup';
import { Loading } from 'components/common';
import { Dialog, InputField } from 'components/common';
import { actions } from 'store/slice/account';
import { selectAddFunds } from 'store/selectors/account';
import styles from 'assets/styles/account.module.scss';
import { getCurrencySymbol } from 'utils/helpers';
import { getInitialFundValue } from './utils';
import DialogV2 from 'components/common/DialogV2';

const fundsValidationSchema = yup.object({
    amountStr: yup.string(),
    amount: yup
        .number()
        .required('Amount is required')
        .positive('Amount must be greater than zero')
        .integer('Amount must be an integer')
        .max(99999, 'Amount must be at most 5 digits'),
});

export default function AddFundsModal({ displayModal, handleModal, accountMeta }) {
    const dispatch = useDispatch();
    const { loading, message, error } = useSelector(selectAddFunds);
    const isLoaded = !!message;
    const { currency, accountId } = accountMeta;
    const currencySymbol = getCurrencySymbol(currency);

    const formik = useFormik({
        initialValues: getInitialFundValue(currencySymbol),
        validationSchema: fundsValidationSchema,
        onSubmit: async ({ amount }) => {
            const fundsDetails = {
                amount,
                accountId,
            };
            if (formik.isValid) {
                dispatch(actions.addAccountsFunds(fundsDetails));
            }
        },
    });

    const getAmount = (val) => val.split(' ')[1];

    const validateInput = (value) => {
        const escapedSymbol = currencySymbol.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const currencyPattern = new RegExp(`^${escapedSymbol}\\s\\d{0,5}$`, 'u');
        return currencyPattern.test(value);
    };

    const handleChange = (event) => {
            const { value } = event.target;

            if (validateInput(value)) {
                const amount = getAmount(value);

                formik.setValues({
                    amountStr: value,
                    amount,
                });
            }
        };

    const onModalHandle = (val) => {
        dispatch(actions.resetAccountsFundsState());
        handleModal(val);
    };

    const onAddAccountFundsSubmit = () => {
        const fundsDetails = {
            amount: formik.values.amount,
            accountId,
        };
        if (formik.isValid) {
            dispatch(actions.addAccountsFunds(fundsDetails));
        }
    };

    const renderBody = () => {
        return (
            <InputField
                name={'amount'}
                label={'Enter the amount'}
                value={formik.values.amountStr}
                onChange={handleChange}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount}
                required={true}
                disable={loading}
            />
        );
    };

    useEffect(() => {
        if (displayModal) {
            formik.setValues(getInitialFundValue(currencySymbol));
        }
        // eslint-disable-next-line
    }, [displayModal]);

    return (
        <>
            {isLoaded ? (
                <DialogV2
                    title={'Add funds'}
                    open={displayModal}
                    isSingleButton={true}
                    setOpen={() => onModalHandle(false)}
                    submitButton={'Close'}
                    variant={error ? 'error' : 'info'}
                    submitAction={() => onModalHandle(false)}>
                    <div className={cx(styles.messageText)}>
                        <p>{message}</p>
                    </div>
                </DialogV2>
            ) : (
                <Dialog
                    title={'Add funds'}
                    fullWidth
                    open={displayModal}
                    setOpen={onModalHandle}
                    submitButton={isLoaded ? 'Close' : 'Add funds'}
                    cancelButton={'Cancel'}
                    isSingleButton={isLoaded}
                    cancelAction={() => onModalHandle(false)}
                    submitAction={() => {
                        if (isLoaded) {
                            onModalHandle(false);
                        } else {
                            onAddAccountFundsSubmit();
                        }
                    }}
                    isActions={true}
                    isDisable={loading || !formik.isValid}
                    backdropProps={{
                        style: {
                            backgroundColor: 'rgba(255,255,255, 0.8)',
                        },
                    }}>
                    <form className={cx(styles.form)} onSubmit={formik.handleSubmit}>
                        {loading && <Loading className={cx(styles.formLoading)} />}
                        {renderBody()}
                    </form>
                </Dialog>
            )}
        </>
    );
}
