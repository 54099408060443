import { createTheme } from '@mui/material/styles';

export const getCurrencyOptions = (currencyCodes, currencyReferenceList) => {
    if (currencyCodes?.includes('ALL')) return currencyReferenceList;

    return currencyReferenceList?.filter(({ currency }) => currencyCodes?.includes(currency)) ?? [];
};

export const getCurrencyCodes = (currencyOptions) =>
    currencyOptions.map(({ currency }) => currency);

export const materialTheme = (selectedCurrencies = [], isSingleSelect = false, variant = null) =>
    createTheme({
        components: {
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        colorPrimary: '#00A09B',
                        color: '#00A09B',
                        '&.Mui-checked': {
                            color: '#00A09B', // Color when checked
                        },
                        '&.MuiCheckbox-indeterminate': {
                            color: '#00A09B',
                        },
                        paddingLeft: '0',
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    root: {
                        width: '100%',
                    },
                    inputRoot: {
                        padding: variant === 'form' ? '10px 20px' : '2px 20px',
                        borderRadius: '15px',
                        backgroundColor: '#fff',
                        transition: 'padding 200ms ease-out',
                        '&.Mui-focused': {
                            padding: selectedCurrencies.length > 0 ? '12px 20px' : '',
                            borderColor: '#016C87',
                            boxShadow: '0 0 5px #016C87',
                            maxHeight: '130px',
                            overflowY: 'auto',
                        },
                        maxHeight: '40px',
                    },
                    listbox: {
                        width: '100%',
                        overflow: 'initial',
                        '.MuiAutocomplete-option': {
                            '&[aria-selected="true"]': {
                                backgroundColor: isSingleSelect
                                    ? 'rgba(0, 160, 155, 0.1)'
                                    : 'transparent',
                                '&:hover': {
                                    backgroundColor: isSingleSelect
                                        ? 'rgba(0, 160, 155, 0.1)'
                                        : 'transparent',
                                },
                            },
                            '&[aria-selected="true"].Mui-focused': {
                                backgroundColor: isSingleSelect
                                    ? 'rgba(0, 160, 155, 0.1)'
                                    : 'transparent',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 160, 155, 0.1)',
                                },
                            },
                            '&:hover': {
                                backgroundColor: 'rgba(0, 160, 155, 0.1)',
                            },
                        },
                        '.MuiAutocomplete-option.Mui-focused': {
                            backgroundColor: 'rgba(0, 160, 155, 0.1)',
                        },
                    },
                    popper: {
                        margin: '10px 0',
                    },
                    paper: {
                        '&::-webkit-scrollbar': {
                            width: '14px',
                            height: '14px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            height: '6px',
                            border: '4px solid rgba(0, 0, 0, 0)',
                            backgroundClip: 'padding-box',
                            borderRadius: '7px',
                            backgroundColor: 'rgba(0, 160, 155, 0.3)',
                            boxShadow:
                                'inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05)',
                        },
                        '&::-webkit-scrollbar-button': {
                            width: 0,
                            height: 0,
                            display: 'none',
                        },
                        '&::-webkit-scrollbar-corner': {
                            backgroundColor: 'transparent',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'transparent',
                        },
                    },
                    option: {
                        '&.MuiMenuItem-root': {
                            marginLeft: 0,
                            padding: isSingleSelect && '15px 22px',
                        },
                    },
                    popupIndicator: {
                        color: '#999',
                    },
                },
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'rgba(0, 160, 155, 0.1)',
                        height: '28px',
                        borderRadius: '12px',
                    },
                },
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        borderRadius: '15px',
                        zIndex: 1,
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#fff',
                        boxShadow: '(0 0 10px rgba(0, 0, 0, 0.25))',
                        zIndex: 1,
                    },
                },
            },
        },
    });
