import React from 'react';
import { Collapse } from '@mui/material';
import cx from 'classnames';
import styles from 'assets/styles/recipients.module.scss';
import { getCurrencyDescription, toCapitalize, toDateTimeString } from 'utils/helpers';
import CurrencyIcon from 'components/common/CurrencyIcon';
import { CustomTooltip, Icon } from 'components/common';
import { Country } from 'country-state-city';
import CountryIcon from 'components/common/CountryIcon';
import { paymentPurposeTypes } from './types';

const RecipientDetails = ({ recipient, isOpen, onDelete, onEdit }) => {
    const countries = Country.getAllCountries();
    const recipientCountry = countries.find(
        (country) => country.isoCode === recipient?.recipient_address?.country
    );
    const recipientBankCountry = countries.find((country) => country.isoCode === recipient.country);

    const recipientAddress = [
        recipient?.recipient_address?.line1,
        recipient?.recipient_address?.line2,
        recipient?.recipient_address?.city,
        recipient?.recipient_address?.state,
        recipient?.recipient_address?.postcode,
        recipientCountry?.name,
    ]
        .filter((e) => e)
        .join(', ');

    const purposeCodeDetails = paymentPurposeTypes.find(x => x.value === recipient.default_purpose);

    const renderAccountHolderName = () => {
        return (
            <div className={cx(styles.data)}>
                <div style={{ display: 'flex' }}>
                    <CurrencyIcon currency={recipient.currency} rounded width="45px" />
                    <div style={{ marginLeft: '20px' }}>
                        <div className={cx(styles.label)}>Account holder name</div>
                        <div className={cx(styles.value)}>{recipient.account_holder_name}</div>
                    </div>
                </div>
            </div>
        );
    };
    const renderDataBlock = (label, value) => {
        return (
            <div className={cx(styles.data)}>
                <div className={cx(styles.label)}>{label}</div>
                <div className={cx(styles.value)}>{value}</div>
            </div>
        );
    };

    return (
        <Collapse
            in={isOpen}
            timeout="auto"
            unmountOnExit
            key={recipient.id}
            className={cx(styles.collapsible)}>
            <div className={cx(styles.section)} style={{ width: '98%' }}>
                <div className={cx(styles.itemHeader)}>
                    <div className={cx(styles.sectionTitle)}>Bank account details</div>
                    <div>
                        <div className={cx(styles.date)}>
                            <div className={cx(styles.label)}>Created on : </div>
                            <div className={cx(styles.value)}>
                                {toDateTimeString(recipient.created_at)}
                            </div>
                            <div className={cx(styles.seperator)}>|</div>
                            <div className={cx(styles.label)}>Last updated : </div>
                            <div className={cx(styles.value)}>
                                {toDateTimeString(recipient.updated_at)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx(styles.segment)}>
                    <div
                        className={cx(styles.details)}
                        style={{flexWrap: 'nowrap', width: '100%', alignItems: 'center'}}>
                        {renderDataBlock('Sending funds to', <>
                            <Icon name={`recipient-${recipient.type}`}></Icon>{toCapitalize(recipient.type)}
                        </>)}
                        {renderDataBlock(
                            'Currency',
                            `${getCurrencyDescription(recipient.currency)} (${recipient.currency})`
                        )}
                        {renderDataBlock('Payment purpose', 
                            purposeCodeDetails ? 
                                <>
                                    {purposeCodeDetails?.default_purpose_code} - {purposeCodeDetails?.default_purpose}
                                    <CustomTooltip info={`${purposeCodeDetails?.default_purpose_code} - ${purposeCodeDetails?.default_purpose} ` + (purposeCodeDetails.additional_purpose ? ` ; ${purposeCodeDetails.additional_purpose}`: '')}>
                                        <Icon name={'info'}/>
                                    </CustomTooltip>
                                </> : '-')}
                        <div>
                            <CustomTooltip info={'Click here to delete the recipient'} enableInfoStyle={false}>
                                <Icon name='delete' onClick={onDelete} className={cx(styles.deleteIcon)}/>
                            </CustomTooltip>
                        </div>
                    </div>
                </div>
                <div className={cx(styles.segment)}>
                    <div className={cx(styles.details)}>
                        {renderAccountHolderName()}
                        {renderDataBlock(
                            'Bank address',
                            <div style={{ display: 'flex' }}>
                                <CountryIcon countryCode={recipient.country} rounded width="18px" />
                                <div style={{ marginLeft: '5px' }}>
                                    {recipientBankCountry?.name}
                                </div>
                            </div>
                        )}
                        {recipient.account_number &&
                            renderDataBlock('Account number', recipient.account_number)}
                        {recipient.iban && renderDataBlock('IBAN', recipient.iban)}
                        {recipient.sort_code && renderDataBlock('Sort code', recipient.sort_code)}
                        {recipient.bic && renderDataBlock('BIC', recipient.bic)}
                    </div>
                </div>
                <div className={cx(styles.sectionTitle)}>Recipient details</div>
                <div className={cx(styles.segment)}>
                    <div className={cx(styles.details)}>
                        {recipient.type === 'business' &&
                            renderDataBlock('Business name', toCapitalize(recipient.company_name))}
                        {recipient.type === 'person' &&
                            renderDataBlock(
                                'Person name',
                                `${recipient.first_name} ${recipient.last_name}`
                            )}
                        {renderDataBlock('', '')}
                        {renderDataBlock(
                            'Nick name',
                            recipient?.nickname ? (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>{recipient?.nickname} </div>
                                    <CustomTooltip info={'Click here to update the nickname'}>
                                        <Icon
                                            name={'edit'}
                                            width="20px"
                                            height="20px"
                                            onClick={() => onEdit(recipient)}
                                        />
                                    </CustomTooltip>
                                </div>
                            ) : (
                                <p className={cx(styles.link)} onClick={() => onEdit(recipient)}>
                                    Add nickname
                                </p>
                            )
                        )}
                        {renderDataBlock('Email', recipient.email ?? '-')}
                        <div className={cx(styles.data)} style={{ width: '100%' }}>
                            <div className={cx(styles.label)}>Residence address</div>
                            <div className={cx(styles.value)}>
                                {recipientAddress ? recipientAddress : '-'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Collapse>
    );
};

export default RecipientDetails;
