import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import cx from 'classnames';
import { Icon, InputField, MultiSelectField, Pagination } from 'components/common';
import styles from 'assets/styles/account.module.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import selectAccountsState from 'store/selectors/account';
import { actions } from 'store/slice/account';
import AccountList from './AccountList';
import { accountStatuses } from './constants';
import { getCurrencyDescription, isEqual } from 'utils/helpers';
import BreadcrumbsNavigator from 'components/common/Breadcrumbs';
import CurrencyIcon from 'components/common/CurrencyIcon';
import { useFormik } from 'formik';
import { ButtonV2 } from 'components/common/Button';

const initialValues = {
    status: [],
    search: '',
};

const Accounts = () => {
    const dispatch = useDispatch();
    const { loading, currencyAccounts } = selectAccountsState();
    const { currency } = useParams();
    const [pageMeta, setPageMeta] = useState({ page: 1, size: 10, currency });
    const [appliedFilterValues, setAppliedFilterValues] = useState(initialValues);

    const formik = useFormik({
        initialValues,
        onSubmit: (values) => {
            if (values && !loading) {
                setAppliedFilterValues(values);
                handleFilter();
            }
        },
        onReset: () => {
            formik.setValues(initialValues);
            setAppliedFilterValues(initialValues);
            handleFilter();
        },
    });

    const handleFilter = (page = 1, size = 10) => {
        setPageMeta({ page, size, currency });
        dispatch(
            actions.getCurrencyAccounts({
                currency,
                page,
                size,
                status: formik.values.status,
                search: formik.values.search,
            })
        );
    };

    const isApplyDisabled = useMemo(
        () => isEqual(formik.values, appliedFilterValues),
        [formik.values, appliedFilterValues]
    );

    const isClearDisabled = useMemo(
        () => isEqual(initialValues, formik.values) && isEqual(initialValues, appliedFilterValues),
        [appliedFilterValues, formik.values]
    );

    useEffect(() => {
        handleFilter();
        // eslint-disable-next-line
    }, []);

    const handlePerPageChange = (value) => {
        handleFilter(1, value);
    };

    const handlePageChange = (page, perPage) => {
        handleFilter(page, perPage);
    };

    const handleSelect = (name, value) => {
        formik.setValues((prev) => ({ ...prev, [name]: value }));
    };

    const handleChange = (event) => {
        formik.setValues((data) => ({
            ...data,
            [event.target.name]: event.target.value?.trim(),
        }));
    };

    const refreshAccounts = () => {
        const { page, size } = pageMeta;
        handleFilter(page, size);
    };

    return (
        <Grid container className={cx(styles.container)}>
            <div className={cx(styles.header)}>
                <CurrencyIcon rounded currency={currency} height="30px" />
                <h1 className={cx(styles.title)}>{getCurrencyDescription(currency)}</h1>
                <Icon name={'refresh'} onClick={refreshAccounts} />
            </div>
            <BreadcrumbsNavigator
                prevPage={{ title: 'Accounts', href: '/dashboard/accounts' }}
                currentPage={{ title: getCurrencyDescription(currency) }}
            />
            <Grid container>
                <Grid item sm={12} className={styles.subFilter} sx={{ marginTop: '32px' }}>
                    <div style={{ position: 'relative' }}>
                        <InputField
                            label={'Account:'}
                            placeholder="Search by name or account details"
                            hasIcon
                            fullWidth
                            iconName="search"
                            iconPosition="start"
                            name={'search'}
                            value={formik.values.search}
                            className={cx(styles['search-accounts'])}
                            onChange={handleChange}
                        />
                    </div>
                    <div className={styles.status}>
                        <MultiSelectField
                            name={'status'}
                            label={'Status:'}
                            defaultText={'Select status'}
                            options={accountStatuses}
                            onChange={handleSelect}
                            value={formik.values.status}
                            selectClassName={cx(styles.statusField)}
                        />
                    </div>
                    <div className={cx(styles.actions)}>
                        <ButtonV2
                            text="Clear"
                            variant="secondary"
                            onClick={formik.handleReset}
                            disabled={isClearDisabled}
                        />
                        <ButtonV2
                            text="Apply"
                            onClick={formik.handleSubmit}
                            disabled={isApplyDisabled}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                {loading && (
                    <div style={{ width: '100%', marginTop: '56px' }}>
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <Skeleton variant="circular" width={40} height={40} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <Skeleton variant="circular" width={40} height={40} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <Skeleton variant="circular" width={40} height={40} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                </ListItemText>
                            </ListItem>
                        </List>
                    </div>
                )}
                {!loading && currencyAccounts?.total === 0 && (
                    <h3 className={cx(styles.noData)}>No data found</h3>
                )}
                {!loading && <AccountList accounts={currencyAccounts?.items} pageMeta={pageMeta} />}
                {!loading && (
                    <Pagination
                        data={currencyAccounts}
                        pageChange={handlePageChange}
                        perPageChange={handlePerPageChange}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default Accounts;
