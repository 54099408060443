import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import cx from 'classnames';
import { actions } from 'store/slice/reports';
import { DatePicker } from 'components/common/';
import { InputField, MultiSelectField } from 'components/common';
import styles from 'assets/styles/reports.module.scss';
import { masReportsStatus } from './constant';
import { ButtonV2 } from 'components/common/Button';
import { isEqual } from 'utils/helpers';

const validationSchema = yup.object({
    username: yup.string('Enter username'),
    requestedDate: yup.date().nullable(),
    status: yup.array().of(yup.string()),
    jobId: yup.string('Enter Job ID'),
    searchAccount: yup.string('Enter account details'),
});

const initialValues = {
    username: '',
    requestedDate: null,
    status: [],
    jobId: '',
    searchAccount: '',
};

const MASReportsFilter = ({ onFilter }) => {
    const dispatch = useDispatch();
    const [appliedFilterValues, setAppliedFilterValues] = useState(initialValues);
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
    });
    const { username, requestedDate, status, jobId, searchAccount } = formik.values;

    const isApplyDisabled = useMemo(
        () => isEqual(formik.values, appliedFilterValues),
        [formik.values, appliedFilterValues]
    );

    const isClearDisabled = useMemo(
        () => isEqual(initialValues, formik.values) && isEqual(initialValues, appliedFilterValues),
        [appliedFilterValues, formik.values]
    );

    const handleInputChange = useCallback(
        (event) => {
            formik.setValues((data) => ({
                ...data,
                [event.target.name]: event.target.value?.trim(),
            }));
        },
        [formik]
    );

    const handleSelect = useCallback(
        (name, value) => {
            formik.setValues((prev) => ({ ...prev, [name]: value }));
        },
        [formik]
    );

    const handlePickerChange = useCallback(
        (date) => {
            formik.setValues((prev) => ({
                ...prev,
                requestedDate: date,
            }));
        },
        [formik]
    );

    const handleApply = () => {
        if (isApplyDisabled) return;

        setAppliedFilterValues(formik.values);
        onFilter(formik.values);
    };

    const handleClear = () => {
        if (isClearDisabled) return;

        formik.resetForm();
        setAppliedFilterValues(initialValues);
        onFilter({});
    };

    useEffect(() => {
        dispatch(actions.fetchMASReports({ page: 1, size: 10 }));
    }, [dispatch]);

    return (
        <Box container className={styles.filterItem}>
            <InputField
                name="username"
                placeholder="Search by user name"
                hasIcon
                fullWidth
                iconName="search"
                iconPosition="start"
                label="Requested by"
                value={username}
                inputClassname={styles.reportsInputField}
                labelClassname={styles.inputLabel}
                onChange={handleInputChange}
            />
            <div>
                <DatePicker
                    label="Requested date"
                    name="requestedDate"
                    value={requestedDate ?? null}
                    onChange={handlePickerChange}
                    textFieldClassname={styles.datepickerField}
                    labelClassname={styles.inputLabel}
                />
            </div>

            <MultiSelectField
                name="status"
                label="Status"
                defaultText="Select request status"
                options={masReportsStatus}
                onChange={handleSelect}
                disableItems={['all']}
                value={status}
                error={formik.touched.status && Boolean(formik.errors.status)}
                helperText={formik.touched.status && formik.errors.status}
                enableDefaultStyle={false}
                selectFieldClassname={styles.selectField}
                labelClassname={styles.inputLabel}
            />

            <InputField
                name="jobId"
                placeholder="Search by job ID"
                hasIcon
                fullWidth
                iconName="search"
                iconPosition="start"
                value={jobId}
                inputClassname={cx(styles.reportsInputField)}
                onChange={handleInputChange}
            />

            <InputField
                placeholder="Search by account details"
                name="searchAccount"
                hasIcon
                fullWidth
                iconName="search"
                iconPosition="start"
                value={searchAccount}
                inputClassname={cx(styles.reportsInputField)}
                onChange={handleInputChange}
            />
            <Box className={styles.actionBtnWrapper}>
                <ButtonV2
                    text="Clear"
                    variant="secondary"
                    className={styles.actionBtn}
                    size="sm"
                    onClick={handleClear}
                    disabled={isClearDisabled}
                />
                <ButtonV2
                    text="Apply"
                    variant="secondary"
                    onClick={handleApply}
                    className={styles.actionBtn}
                    size="sm"
                    disabled={isApplyDisabled}
                />
            </Box>
        </Box>
    );
};

export default MASReportsFilter;
