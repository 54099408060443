import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slice/pendingpayments';
import { request } from 'utils/api';
import Endpoints from 'utils/endpoints';
import moment from 'moment';
import { getAccessToken } from 'utils/auth';

const {
    payment_request,
    accounts: { accountDetails, account },
    recipients: { recipient },
} = Endpoints;

export function* getPendingPayments(action) {
    const { page, size, queryParams } = action.payload;
    const searchParameters = new URLSearchParams(queryParams);
    const from_date = searchParameters.get('from_date');
    const to_date = searchParameters.get('to_date');
    if (from_date) {
        searchParameters.set('from_date', moment(from_date + 'T00:00:00').format('X'));
    }
    if (to_date) {
        searchParameters.set('to_date', moment(to_date + 'T23:59:59').format('X'));
    }
    if (searchParameters.has('currency')) {
        const currencies = searchParameters.getAll('currency');
        if (currencies.includes('all')) {
            searchParameters.delete('currency');
        }
    }

    try {
        const { data } = yield call(
            request().get,
            payment_request.get.url(page, size, searchParameters.toString(), null)
        );
        if (data) {
            yield put(actions.getPendingPaymentsSuccess(data));
        }
    } catch (err) {
        yield put(actions.getPendingPaymentsError(err.message));
    }
}

export function* getApprovePaymentOtp(action) {
    const { amount, currency, to, payment_id } = action.payload;
    try {
        const response = yield call(request().post, 'auth/generate-request-otp', {
            message: `{otp} is your Merge OTP for the amount ${amount} ${currency} for ${to}. Do not share.`,
            subject: 'Approval of pending payment is initiated',
            request: {
                path: `//v1/payment-request/${payment_id}/approve`,
                method: 'POST',
                payload: {},
            },
            token: getAccessToken(),
            delivery_channel: 'sms',
        });
        if (response) {
            yield put(actions.getApprovePaymentOtpSuccess(response.data));
        }
    } catch (err) {
        yield put(
            actions.getApprovePaymentOtpError({
                message: err.message,
                status: 403,
            })
        );
    }
}

export function* approvePendingPayment(action) {
    try {
        const { payment_id } = action.payload;
        const { data } = yield call(
            request().post,
            `${payment_request.post.url}/${payment_id}/approve`,
            {},
            {
                headers: {
                    X_OTP_CODE: action.payload.approvePaymentOtp,
                    X_OTP_TOKEN: action.payload.approvePaymentOtpToken,
                },
            }
        );
        if (data) {
            yield put(actions.approvePendingPaymentsSuccess(data));
        }
    } catch (err) {
        yield put(
            actions.approvePendingPaymentsError({
                message: 'Invalid OTP',
                status: 403,
            })
        );
    }
}

export function* rejectPendingPayment(action) {
    try {
        const { payment_id } = action.payload;
        const { data } = yield call(
            request().post,
            `${payment_request.post.url}/${payment_id}/reject`,
            {}
        );
        if (data) {
            yield put(actions.rejectPendingPaymentsSuccess(data));
        }
    } catch (err) {
        yield put(actions.rejectPendingPaymentsError(err.message));
    }
}

export function* getReviewAccount(action) {
    try {
        const { account_id } = action.payload;
        const { data } = yield call(request().get, accountDetails.url(account_id));
        if (data) {
            yield put(actions.getReviewAccountSuccess(data));
        }
    } catch (err) {
        const { data } = err?.response;
        yield put(actions.getReviewAccountError(data.message));
    }
}

export function* getReviewRecipient(action) {
    try {
        const { recipient_id } = action.payload;
        const { data } = yield call(request().get, `${recipient.url}/${recipient_id}`);
        if (data) {
            yield put(actions.getReviewRecipientSuccess(data));
        }
    } catch (err) {
        const { data } = err?.response;
        yield put(actions.getReviewRecipientError(data.message));
    }
}

export function* getReviewQuote(action) {
    const { url } = account;

    const { source_account, recipient, source_amount, reference } = action.payload;
    const quote_payload = {
        source_currency: source_account.currency,
        source_amount: source_amount,
        recipient_id: recipient.id,
        destination_currency: recipient.currency,
        ...(reference ? { reference: reference } : {}),
    };
    try {
        const { data } = yield call(
            request().post,
            `${url}/${action.payload.source_account.id}/quote`,
            quote_payload
        );
        if (data) {
            yield put(actions.getReviewQuoteSuccess(data));
        }
    } catch (err) {
        const { data, status } = err.response;
        yield put(
            actions.getReviewQuoteError({
                message: data.message ? data.message : data.detail,
                status: status,
            })
        );
    }
}

export function* paymentsSaga() {
    yield takeLatest(actions.getPendingPayments.type, getPendingPayments);
    yield takeLatest(actions.rejectPendingPayments.type, rejectPendingPayment);
    yield takeLatest(actions.getApprovePaymentOtp.type, getApprovePaymentOtp);
    yield takeLatest(actions.approvePendingPayments.type, approvePendingPayment);
    yield takeLatest(actions.getReviewAccount.type, getReviewAccount);
    yield takeLatest(actions.getReviewRecipient.type, getReviewRecipient);
    yield takeLatest(actions.getReviewQuote.type, getReviewQuote);
}
