import React from 'react';
import { CustomTooltip, Icon } from 'components/common';
import cx from 'classnames';
import styles from 'assets/styles/recipients.module.scss';
import CurrencyIcon from 'components/common/CurrencyIcon';
import { Country } from 'country-state-city';
import { toCapitalize } from 'utils/helpers';
import CountryIcon from 'components/common/CountryIcon';
import { paymentPurposeTypes } from './types';

const ReviewBankDetails = ({ recipient, onEdit }) => {
    const countries = Country.getAllCountries();
    const recipientBankCountry = countries.find((country) => country.isoCode === recipient.country);
    const purposeCodeDetails = paymentPurposeTypes.find(
        (x) => x.value === recipient.default_purpose
    );

    const renderAccountHolderName = () => {
        return (
            <div className={cx(styles.data)}>
                <div className={styles.dataBlock}>
                    <CurrencyIcon currency={recipient.currency} rounded width="45px" />
                    <div style={{ marginLeft: '20px' }}>
                        <div className={cx(styles.label)}>Account holder name</div>
                        <div className={cx(styles.value)}>{recipient.account_holder_name}</div>
                    </div>
                </div>
            </div>
        );
    };
    const renderDataBlock = (label, value) => {
        return (
            <div className={cx(styles.data)}>
                <div className={cx(styles.label)}>{label}</div>
                <div className={cx(styles.value)}>{value}</div>
            </div>
        );
    };
    return (
        <div>
            <div className={cx(styles.sectionTitle)}>Bank account details</div>
            <div className={cx(styles.section)}>
                <div className={cx(styles.segment)}>
                    <div className={cx(styles.details)}>
                        {renderDataBlock('Sending funds to', toCapitalize(recipient.type))}
                        {renderDataBlock(
                            'Payment purpose',
                            purposeCodeDetails ? (
                                <>
                                    {purposeCodeDetails?.default_purpose_code} -{' '}
                                    {purposeCodeDetails?.default_purpose}
                                    <CustomTooltip
                                        info={
                                            `${purposeCodeDetails?.default_purpose_code} - ${purposeCodeDetails?.default_purpose} ` +
                                            (purposeCodeDetails.additional_purpose
                                                ? ` ; ${purposeCodeDetails.additional_purpose}`
                                                : '')
                                        }>
                                        <Icon name={'info'} />
                                    </CustomTooltip>
                                </>
                            ) : (
                                '-'
                            )
                        )}
                    </div>
                    <div className={cx(styles.action)}>
                        <div className={styles.editDetails} onClick={onEdit}>
                            <CustomTooltip info={'Click here to edit bank details'} enableInfoStyle={false}>
                                <Icon name="edit" color="#00A09B" />
                            </CustomTooltip>
                        </div>
                    </div>
                </div>
                <div className={cx(styles.segment)}>
                    <div className={cx(styles.details)}>
                        {renderAccountHolderName()}
                        {renderDataBlock(
                            'Bank address',
                            <div className={styles.dataBlock}>
                                <CountryIcon countryCode={recipient.country} rounded width="18px" />
                                <div style={{ marginLeft: '5px' }}>
                                    {recipientBankCountry?.name}
                                </div>
                            </div>
                        )}
                        {recipient.account_number &&
                            renderDataBlock('Account number', recipient.account_number)}
                        {recipient.iban && renderDataBlock('IBAN', recipient.iban)}
                        {recipient.sort_code && renderDataBlock('Sort code', recipient.sort_code)}
                        {recipient.bic && renderDataBlock('BIC', recipient.bic)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewBankDetails;
