import React, { useState, useMemo, useRef, useEffect } from 'react';
import {
    Autocomplete,
    TextField,
    ListItemIcon,
    ListItemText,
    MenuItem,
    InputAdornment,
} from '@mui/material';
import cx from 'classnames';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { currencyList } from 'utils/helpers';
import { ThemeProvider } from '@emotion/react';
import CustomTooltip from '../CustomTooltip';
import Icon from '../Icon';
import styles from 'assets/styles/common.module.scss';
import { getCurrencyOptions, materialTheme } from './utils';
import CurrencyIcon from '../CurrencyIcon';

const SingleSelectAutocomplete = ({
    name,
    className,
    selectClassName,
    label,
    handleChange,
    value,
    currencies = [],
    required = false,
    info = '',
    error = false,
    disable = false,
    helperText = '',
    variant = null,
}) => {
    const [isFocus, setFocus] = useState(false);
    let inputRef = useRef(null);
    const currencyListOptions = useMemo(() => {
        if (currencies.length) {
            return getCurrencyOptions(currencies, currencyList);
        }

        return currencyList;
    }, [currencies]);
    const [selectedCurrency, setSelectedCurrency] = useState(null);

    useEffect(() => {
        if (currencies.length > 0) {
            if (!currencies.find((x) => x === value)) {
                setSelectedCurrency(null);
                return;
            }
        }
        const selectedOption = currencyList.find((x) => x.currency === value);
        setSelectedCurrency(selectedOption);
    }, [currencies, value]);

    const onSelectChange = (event, val) => {
        handleChange(name, val?.currency ?? null);
    };

    const onAutcompleteFocus = () => {
        if (!isFocus) {
            setFocus(true);
        }
    };

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: ({ currencyName, currency, countryCode }) =>
            currency && `${currencyName} ${countryCode}`,
    });

    return (
        <div className={className} style={{ height: error ? '110px' : '80px' }}>
            <div className={cx(disable ? styles.disable : styles.label)}>
                {label}
                {required && <span className={cx(styles.asterisk)}> *</span>}
                {info && (
                    <CustomTooltip info={info}>
                        <Icon name={'info'} />
                    </CustomTooltip>
                )}
            </div>
            <ThemeProvider theme={materialTheme([], true, variant)}>
                <div
                    className={cx([
                        error ? styles.errorBorder : '',
                        styles[
                            variant === 'form' ? 'selectFormVariant' : 'autocompleteFieldOverride'
                        ],
                    ])}>
                    <Autocomplete
                        id="currency-select"
                        name={name}
                        className={cx(selectClassName, styles.autocompleteScroll)}
                        value={selectedCurrency}
                        onFocus={onAutcompleteFocus}
                        onBlur={() => setFocus(false)}
                        onChange={onSelectChange}
                        options={currencyListOptions}
                        getOptionLabel={({ currencyName }) => currencyName}
                        filterOptions={filterOptions}
                        disabled={disable}
                        disableListWrap
                        disablePortal
                        componentsProps={{
                            popupIndicator: {
                                disableRipple: true,
                            },
                        }}
                        // disableClearable
                        renderOption={(props, option, selectState) => {
                            const { key } = props;
                            const { selected } = selectState;
                            const { currency, currencyName } = option;

                            return (
                                <MenuItem
                                    key={key}
                                    value={currencyName || ''}
                                    defaultValue={currencyName || ''}
                                    className={cx([
                                        styles.multiSelectMenuItem,
                                        selected && styles.selectMenuItemActive,
                                        styles.listItem,
                                    ])}
                                    {...props}>
                                    {currency && (
                                        <ListItemIcon>
                                            <CurrencyIcon
                                                style={{
                                                    paddingRight: '10px',
                                                }}
                                                rounded
                                                currency={currency}
                                                height="20px"
                                            />
                                        </ListItemIcon>
                                    )}
                                    <ListItemText primary={currencyName} />
                                </MenuItem>
                            );
                        }}
                        renderInput={(params) => {
                            const { inputProps, InputProps } = params;
                            const { value } = InputProps.endAdornment.props.ownerState;
                            inputRef.current = inputProps.ref.current;

                            return (
                                <TextField
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <CurrencyIcon
                                                    rounded
                                                    currency={value?.currency}
                                                    height="20px"
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        backgroundColor: '#fff',
                                        opacity: 1,
                                        '& .MuiInputBase-root': {
                                            backgroundColor: '#fff',
                                            opacity: 1,
                                        },
                                    }}
                                    placeholder={
                                        isFocus ? 'Type Euro or EUR or €' : 'Select Currency'
                                    }
                                />
                            );
                        }}
                    />
                    {helperText && <p className={cx(styles.asterisk)}>{helperText}</p>}
                </div>
            </ThemeProvider>
        </div>
    );
};

export default SingleSelectAutocomplete;
