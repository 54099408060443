import React, { useCallback, useEffect, useState } from 'react';
import { RadioGroup, MultiSelectField } from 'components/common';
import { transactionStatuses, transactionTypes, download_format, report_sources } from './types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import cx from 'classnames';
import styles from 'assets/styles/reports.module.scss';
import moment from 'moment/moment';
import { useDispatch } from 'react-redux';
import { actions } from 'store/slice/reports';
import selectState from 'store/selectors/reports';
import { SingleCurrencyAutocomplete } from 'components/common/SelectInput';
import { useSearchParams } from 'react-router-dom';
import selectGlobalSate from 'store/selectors/global';
import SelectAccountV2 from 'components/common/SelectInput/SelectAccountV2';
import RangePickerV2 from 'components/common/Picker/RangePickerV2';
import { ButtonV2 } from 'components/common/Button';

const validationSchema = yup.lazy((values) => {
    let schema = {
        payment_type: yup.array(yup.string()),
        status: yup.array(yup.string()),
        from_date: yup.date().required(),
        to_date: yup.date().required(),
        report_source: yup.string().nullable(),
    };
    const dateRangeValidator = {
        to_date: yup
            .date()
            .required()
            .test('isValidDate', 'Difference can not be greater than 35 days', function () {
                if (!values.from_date && !values.to_date) {
                    return true;
                }
                return moment(values.to_date).diff(moment(values.from_date), 'days') <= 35;
            }),
    };
    let sourceFilter = {};
    if (values.report_source === 'account') {
        sourceFilter = {
            fromAccount: yup.object().required(),
            currency: yup.string().nullable(),
        };
    } else if (values.report_source === 'currency') {
        sourceFilter = {
            fromAccount: yup.object().nullable(),
            currency: yup.string().required(),
        };
    } else {
        sourceFilter = {
            fromAccount: yup.object().nullable(),
            currency: yup.string().nullable(),
        };
    }
    return yup.object({
        ...schema,
        ...dateRangeValidator,
        ...sourceFilter,
    });
});

const ReportsFilterV2 = ({ onDownload }) => {
    const dispatch = useDispatch();
    const { fromAccount, loading } = selectState();
    const [searchParams] = useSearchParams();
    const [fromAccountId, setFromAccountId] = useState(null);
    const { enabledCurrencies } = selectGlobalSate();

    const initialValues = {
        fromAccount: null,
        payment_type: [],
        status: [],
        format: 'xls',
        currency: null,
        from_date: new Date(moment().utc().startOf('month').startOf('day')),
        to_date: new Date(moment().utc().startOf('day')),
        report_source: '',
    };
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {},
        onReset: () => {},
    });

    const handlePickerChange = useCallback(
        (date) => {
            formik.setValues((prev) => ({
                ...prev,
                from_date: date[0] || prev.from_date,
                to_date: date[1] || prev.to_date,
            }));
        },
        [formik]
    );

    const updateValues = (values) => {
        formik.setValues((prev) => ({
            ...prev,
            ...values,
        }));
    };

    const handleInputChange = useCallback(
        (name, value) => {
            formik.setValues((prev) => ({
                ...prev,
                [name]: value,
            }));
        },
        [formik]
    );

    const dowloadReport = useCallback(() => {
        onDownload(formik.values);
    }, [formik, onDownload]);

    useEffect(() => {
        if (searchParams.get('from')) {
            setFromAccountId(searchParams.get('from'));
        }
    }, [searchParams]);

    useEffect(() => {
        if (fromAccountId) {
            dispatch(actions.fetchFromAccount(fromAccountId));
            return;
        }
    }, [dispatch, fromAccountId]);

    useEffect(() => {
        if (fromAccount) {
            updateValues({
                fromAccount: fromAccount,
                report_source: 'account',
            });
        }
        // eslint-disable-next-line
    }, [fromAccount]);

    return (
        <>
            <div className={cx(styles.filterV3)}>
                <div className={cx(styles.row)}>
                    <div className={cx(styles.left)}>
                        <div className={styles.txnDatePicker}>
                            <RangePickerV2
                                label={'Select date range'}
                                required={true}
                                maxDate={moment().toDate()}
                                values={[
                                    formik.values.from_date
                                        ? new Date(formik.values.from_date)
                                        : null,
                                    formik.values.to_date ? new Date(formik.values.to_date) : null,
                                ]}
                                onChange={handlePickerChange}
                            />
                            {formik.errors.to_date && (
                                <div style={{ color: '#EB6161', marginTop: '5px' }}>
                                    {' '}
                                    {formik.errors.to_date}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={cx(styles.right)}>
                        <div className={styles.format}>
                            <RadioGroup
                                className={'report'}
                                label={'Select report format'}
                                name={'format'}
                                required={true}
                                options={download_format}
                                onChange={(event) =>
                                    handleInputChange(event.target.name, event.target.value)
                                }
                                defaultValue={formik.values.format}
                                alignLabel={false}
                                isRow={false}
                            />
                        </div>
                    </div>
                </div>
                <div className={cx(styles.row)}>
                    <div className={cx(styles.left)}>
                        <div>
                            <RadioGroup
                                label={'Transaction report by: '}
                                name={'report_source'}
                                options={report_sources}
                                onChange={(event) =>
                                    handleInputChange(event.target.name, event.target.value)
                                }
                                defaultValue={formik.values.report_source}
                                isRow={true}
                            />
                        </div>
                        <div className={styles.sourcePicker}>
                            {/** Using display attribute instead of conditional statement to avoid re-rendering everytime */}
                            <div
                                className={styles.accountPicker}
                                style={{
                                    display:
                                        formik.values.report_source !== 'account'
                                            ? 'none'
                                            : 'block',
                                }}>
                                <SelectAccountV2
                                    name={'fromAccount'}
                                    value={formik.values.fromAccount?.id}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div
                                className={styles.currencyPicker}
                                style={{
                                    display:
                                        formik.values.report_source !== 'currency'
                                            ? 'none'
                                            : 'block',
                                }}>
                                <SingleCurrencyAutocomplete
                                    value={formik.values.currency}
                                    name={'currency'}
                                    label={'Currency'}
                                    currencies={enabledCurrencies}
                                    handleChange={handleInputChange}
                                    selectClassName={cx(styles.reportSelectFilter)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={cx(styles.right)}>
                        <div className={styles.txnType}>
                            <MultiSelectField
                                name={'payment_type'}
                                label={'Transaction type'}
                                defaultText={'Select transaction type'}
                                options={transactionTypes}
                                onChange={handleInputChange}
                                disableItems={['all']}
                                value={formik.values.payment_type}
                                error={
                                    formik.touched.payment_type &&
                                    Boolean(formik.errors.payment_type)
                                }
                                helperText={
                                    formik.touched.payment_type && formik.errors.payment_type
                                }
                                selectClassName={cx(styles.reportSearchField)}
                            />
                        </div>
                        <div className={styles.txnStatus}>
                            <MultiSelectField
                                name={'status'}
                                label={'Transaction status'}
                                defaultText={'Select status'}
                                options={transactionStatuses}
                                onChange={handleInputChange}
                                disableItems={['all']}
                                value={formik.values.status}
                                error={formik.touched.status && Boolean(formik.errors.status)}
                                helperText={formik.touched.status && formik.errors.status}
                                selectClassName={cx(styles.reportSearchField)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.filter}>
                <div className={styles.actions}>
                    <ButtonV2
                        text={'Clear'}
                        disabled={!formik.dirty}
                        variant={'secondary'}
                        onClick={() => {
                            formik.resetForm(initialValues);
                        }}
                    />

                    <ButtonV2
                        text={loading ? 'Downloading...' : 'Download Report'}
                        disabled={loading || !formik.isValid}
                        size="sm"
                        onClick={dowloadReport}
                    />
                </div>
            </div>
        </>
    );
};

export default ReportsFilterV2;
