import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Chip, Grid } from '@mui/material';
import cx from 'classnames';
import styles from 'assets/styles/profile.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { PasswordStrength, InputField, CustomTooltip } from 'components/common';
import { getUserRole } from 'store/selectors/auth';
import { useFormik } from 'formik';
import * as yup from 'yup';
import selectState from 'store/selectors/profile';
import selectUserState from 'store/selectors/user';

import { logout } from 'store/actions';
import { actions } from 'store/slice/profile';
import DialogV2 from 'components/common/DialogV2';
import { ButtonV2 } from 'components/common/Button';

const Profile = () => {
    const dispatch = useDispatch();
    const { user } = selectUserState();
    const userRoles = useSelector(getUserRole);
    const [userRolesFriendly, setUserRolesFriendly] = useState([]);
    const [showChangePwd, setShowChangePwd] = useState(false);
    const [toggleIcon, setToggleIcon] = useState('show-password');
    const [toggleConfirmIcon, setToggleConfirmIcon] = useState('show-password');
    const [toggleCurrentIcon, setToggleCurrentIcon] = useState('show-password');
    const [errorMessage, setErrorMessage] = useState('');
    const {
        loading,
        error,
        changePassword: { status },
        rolesList,
    } = selectState();
    const [isPwdConfirmationModalOpen, setIsPwdConfirmationModalOpen] = React.useState(false);
    const [timer, setTimer] = useState('0');

    // We need ref in this, because we are dealing
    // with JS setInterval to keep track of it and
    // stop it when needed
    const Ref = useRef(null);

    const validationSchema = yup.object({
        currentPassword: yup
            .string('Enter your current password')
            .required('Current Password is required'),
        password: yup
            .string('Enter your password')
            .required('Password is required')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                'Password should contain at least 8 characters with 1 upper case letter, 1 lower case letter and 1 number'
            )
            .test(
                'is-not-current-password',
                "New password can't be same as existing password",
                function (password) {
                    const { currentPassword } = this.parent;
                    return password !== currentPassword;
                }
            ),
        confirmPassword: yup
            .string('Confirm your password')
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required('Confirm password is required'),
    });

    const getTimeRemaining = useCallback((e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        return {
            total,
            seconds,
        };
    }, []);

    const endTime = useCallback(() => {
        let time = new Date();

        // This is where you need to adjust if
        // you entend to add more time
        time.setSeconds(time.getSeconds() + 9);
        return time;
    }, []);

    const refreshTimer = useCallback(
        (e) => {
            let { total, seconds } = getTimeRemaining(e);
            if (total > 0) {
                setTimer('' + seconds);
            } else if (total <= 0) {
                clearInterval(Ref.current);
                dispatch(logout());
            }
        },
        [dispatch, getTimeRemaining, setTimer]
    );

    const startTimer = useCallback(
        (e) => {
            // If you adjust it you should also need to adjust the Endtime formula we are about to code next.
            setTimer('9');

            // Clearing the previous setInterval if the timer is started again
            if (Ref.current) clearInterval(Ref.current);
            const id = setInterval(() => {
                refreshTimer(e);
            }, 1000);
            Ref.current = id;
        },
        [setTimer, refreshTimer]
    );

    const formik = useFormik({
        initialValues: {
            currentPassword: '',
            password: '',
            confirmPassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(actions.changePassword(values));
        },
    });

    useEffect(() => {
        dispatch(actions.getRoles());
    }, [dispatch]);

    useEffect(() => {
        let userTextRoles = [];

        rolesList.forEach((role) => {
            if (userRoles.indexOf(role.value) > -1) {
                userTextRoles.push(role);
            }
        });
        setUserRolesFriendly(userTextRoles);
    }, [rolesList, userRoles]);

    useEffect(() => {
        if (!status) {
            return;
        }
        if (status === 'success') {
            startTimer(endTime());
            setIsPwdConfirmationModalOpen(true);
        }
    }, [dispatch, status, startTimer, endTime]);

    const handleChange = useCallback(
        (event) => {
            formik.setValues((values) => ({
                ...values,
                [event.target.name]: event.target.value,
            }));
            setErrorMessage('');
        },
        [formik]
    );

    const toggleShowCurrentPassword = useCallback(() => {
        setToggleCurrentIcon(
            toggleCurrentIcon === 'show-password' ? 'hide-password' : 'show-password'
        );
    }, [toggleCurrentIcon]);

    const toggleShowPassword = useCallback(() => {
        setToggleIcon(toggleIcon === 'show-password' ? 'hide-password' : 'show-password');
    }, [toggleIcon]);

    const toggleShowConfirmPassword = useCallback(() => {
        setToggleConfirmIcon(
            toggleConfirmIcon === 'show-password' ? 'hide-password' : 'show-password'
        );
    }, [toggleConfirmIcon]);

    const clearChangePassword = () => {
        dispatch(actions.clearState());
        formik.resetForm();
        setShowChangePwd(false);
    };

    const maskEmail = (email) => {
        if (!email) {
            return '';
        }
        const splitValues = email.split('@');
        const userName = splitValues[0];
        const domainName = splitValues[1];
        const maskedUsername = Array.from(userName, (ch, i) => (Math.floor(i) % 2 ? '*' : ch)).join(
            ''
        );
        const maskedDomainName = Array.from(domainName, (ch, i) =>
            Math.floor(i) % 2 ? '*' : ch
        ).join('');
        return `${maskedUsername}@${maskedDomainName}`;
    };

    const maskPhoneNumber = (phoneNumber) => {
        if (!phoneNumber) {
            return '';
        }
        const maskedPhoneNumber = Array.from(phoneNumber, (ch, i) =>
            Math.floor(i) % 2 ? '*' : ch
        ).join('');
        return maskedPhoneNumber;
    };

    return (
        <Grid container className={cx(styles.container)}>
            <Grid md={6} item>
                <h1 className={cx(styles.title)}>{'Profile'}</h1>
            </Grid>
            <div className={cx(styles.details)}>
                <div className={cx(styles.datarow)}>
                    <div className={cx(styles.header)}>User ID</div>
                    <div className={cx(styles.value)}>{maskEmail(user?.email)}</div>
                </div>
                <div className={cx(styles.datarow)}>
                    <div className={cx(styles.header)}>Name</div>
                    <div className={cx(styles.value)}>
                        {`${user?.first_name || ''} ${user?.last_name || ''}`}
                    </div>
                </div>
                <div className={cx(styles.datarow)}>
                    <div className={cx(styles.header)}>Mobile number</div>
                    <div className={cx(styles.value)}>{maskPhoneNumber(user?.phone_number)}</div>
                </div>
                <div className={cx(styles.datarow)}>
                    <div className={cx(styles.header)}>Roles</div>
                    <div style={{display: 'flex', gap: '15px'}}>
                        {userRolesFriendly.map((role)=>(
                            <CustomTooltip key={role.value} enableInfoStyle={false} info={role.description}>
                                <Chip label={role.label} style={{cursor:'pointer', backgroundColor: 'rgba(0, 160, 155, 0.05)'}} />
                            </CustomTooltip>
                        ))}
                    </div>
                </div>
            </div>
            <div className={cx(styles.data)}>
                <div className={cx(styles.datacol)}>
                    <ButtonV2
                        text="Change Password"
                        disabled={showChangePwd}
                        onClick={() => {
                            setShowChangePwd(true);
                        }}
                    />
                </div>
            </div>
            {showChangePwd && error?.message && (
                <div className={cx(styles.error)}>{error.message}</div>
            )}
            {showChangePwd && (
                <Grid container className={cx(styles.changePassword)}>
                    <form onSubmit={formik.handleSubmit} className={cx(styles.form)}>
                        <InputField
                            type={toggleCurrentIcon === 'show-password' ? 'password' : 'text'}
                            name={'currentPassword'}
                            label={'Type the current password'}
                            required={true}
                            disable={loading}
                            value={formik.values.currentPassword}
                            onChange={handleChange}
                            handleIconClick={toggleShowCurrentPassword}
                            hasIcon={true}
                            iconPosition="end"
                            iconName={toggleCurrentIcon}
                            error={
                                (formik.touched.currentPassword &&
                                    Boolean(formik.errors.currentPassword)) ||
                                errorMessage
                            }
                            helperText={
                                formik.touched.currentPassword && formik.errors.currentPassword
                            }
                        />
                        <InputField
                            type={toggleIcon === 'show-password' ? 'password' : 'text'}
                            name={'password'}
                            label={'Create a new password'}
                            required={true}
                            disable={loading}
                            value={formik.values.password}
                            onChange={handleChange}
                            handleIconClick={toggleShowPassword}
                            hasIcon={true}
                            iconPosition="end"
                            iconName={toggleIcon}
                            error={
                                (formik.touched.password && Boolean(formik.errors.password)) ||
                                errorMessage
                            }
                            helperText={formik.touched.password && formik.errors.password}
                        />
                        {formik.values.password && (
                            <div className={cx(styles.pwdStrengh)}>
                                Password Strength:
                                <PasswordStrength password={formik.values.password} />
                            </div>
                        )}
                        <InputField
                            type={toggleConfirmIcon === 'show-password' ? 'password' : 'text'}
                            name={'confirmPassword'}
                            label={'Re-type the new password'}
                            required={true}
                            disable={loading}
                            value={formik.values.confirmPassword}
                            onChange={handleChange}
                            handleIconClick={toggleShowConfirmPassword}
                            iconName={toggleConfirmIcon}
                            hasIcon={true}
                            iconPosition="end"
                            error={
                                (formik.touched.confirmPassword &&
                                    Boolean(formik.errors.confirmPassword)) ||
                                errorMessage
                            }
                            helperText={
                                formik.touched.confirmPassword && formik.errors.confirmPassword
                            }
                        />
                        <div style={{display: 'flex', gap: '10px', marginTop: '40px'}}>
                            <ButtonV2
                                // className={cx(styles.cancelBtn)}
                                text='Cancel'
                                variant='secondary'
                                onClick={() => {
                                    clearChangePassword();
                                }}/>
                            <ButtonV2
                                text='Save changes'
                                disabled={loading}
                                // className={cx(styles.submitBtn)}
                                onClick={formik.handleSubmit}/>
                        </div>
                    </form>
                </Grid>
            )}
            <DialogV2
                title={'Password change confirmation'}
                open={isPwdConfirmationModalOpen}
                hideActions={true}
                setOpen={() => setIsPwdConfirmationModalOpen(false)}
                variant={'success'}
                disableClose={true}
            >
                {`Your password has been changed successfully. You will be redirected to the login page in ${timer} seconds. Use your new password to log in.`}            
            </DialogV2>
        </Grid>
    );
};

export default Profile;
