import { createSlice } from '@reduxjs/toolkit';

/*
 *  @desc The recipients slice manages the state of the recipients
 *  slice
 *  reducer
 *  actions
 */
export const initialState = {
    loading: false,
    error: false,
    recipients: {
        items: [],
        total: null,
        page: null,
        size: null,
    },
    sendFundRecipients: null,
    addRecipientSuccess: false,
    updateRecipientSuccess: false,
    deleteRecipientSuccess: false,
    fromAccount: null,
    addedRecipient: null,

    addRecipientOtpError: {
        status: 200,
        message: '',
    },
    addRecipientOtp: false,
    addRecipientOtpCount: 0,
    addRecipientToken: null,

    updateRecipientOtpError: {
        status: 200,
        message: '',
    },
    updateRecipientOtp: false,
    updateRecipientOtpCount: 0,
    updateRecipientToken: null,

    deleteRecipientOtpError: {
        status: 200,
        message: '',
    },
    deleteRecipientOtp: false,
    deleteRecipientOtpCount: 0,
    deleteRecipientToken: null,
};

export const recipientsSlice = createSlice({
    name: 'recipients',
    initialState,
    reducers: {
        getRecipients(state) {
            state.loading = true;
            state.error = false;
        },
        getRecipientsSuccess(state, action) {
            state.loading = false;
            state.recipients = action.payload;
            state.error = false;
        },
        getRecipientsError(state, action) {
            state.loading = false;
            state.error = true;
        },

        getSendFundRecipients(state) {
            state.sendFundRecipients = null;
            state.loading = true;
        },
        getSendFundRecipientsSuccess(state, action) {
            state.loading = false;
            state.sendFundRecipients = action.payload?.items;
        },
        getSendFundRecipientsError(state) {
            state.loading = false;
        },

        createRecipients(state) {
            state.loading = true;
            state.addRecipientSuccess = false;
        },
        createRecipientsSuccess(state, action) {
            state.loading = false;
            state.addRecipientSuccess = true;
            state.addedRecipient = action.payload;
        },
        createRecipientsError(state, action) {
            state.loading = false;
            state.addRecipientSuccess = false;
            state.addRecipientOtpError = { ...action.payload };
        },
        resetAddRecipientSuccess(state) {
            state.addRecipientSuccess = false;
        },
        updateRecipients(state) {
            state.loading = true;
            state.updateRecipientSuccess = false;
        },
        updateRecipientsSuccess(state) {
            state.loading = false;
            state.updateRecipientSuccess = true;
        },
        updateRecipientsError(state, action) {
            state.loading = false;
            state.updateRecipientSuccess = false;
            state.updateRecipientOtpError = { ...action.payload };
        },
        resetUpdateRecipientSuccess(state) {
            state.updateRecipientSuccess = false;
        },
        deleteRecipients(state) {
            state.loading = true;
            state.deleteRecipientSuccess = false;
        },
        deleteRecipientsSuccess(state) {
            state.loading = false;
            state.deleteRecipientSuccess = true;
        },
        resetDeleteRecipientSuccess(state) {
            state.deleteRecipientSuccess = false;
        },
        deleteRecipientsError(state, action) {
            state.loading = false;
            state.deleteRecipientSuccess = false;
            state.deleteRecipientOtpError = { ...action.payload };
        },

        getAddRecipientOtp(state) {
            state.loading = true;
            state.addRecipientOtpError = {
                status: false,
                message: '',
            };
        },
        getAddRecipientOtpSuccess: (state, action) => {
            state.addRecipientOtp = true;
            state.addRecipientToken = action.payload?.token;
            state.loading = false;
        },
        getAddRecipientOtpError: (state, action) => {
            state.addRecipientOtp = false;
            state.addRecipientToken = null;
            state.addRecipientOtpError = { ...action.payload };
            state.loading = false;
            state.addRecipientOtpCount = state.addRecipientOtpCount + 1;
        },
        resetAddRecipient: (state) => {
            state.addRecipientOtpError = {
                status: 200,
                message: '',
            };
            state.addRecipientOtp = false;
            state.addRecipientOtpCount = 0;
            state.addRecipientToken = null;
            state.loading = false;
        },
        getUpdateRecipientOtp(state) {
            state.loading = true;
            state.updateRecipientOtpError = {
                status: false,
                message: '',
            };
        },
        getUpdateRecipientOtpSuccess: (state, action) => {
            state.updateRecipientOtp = true;
            state.updateRecipientToken = action.payload?.token;
            state.loading = false;
        },
        getUpdateRecipientOtpError: (state, action) => {
            state.updateRecipientOtp = false;
            state.updateRecipientToken = null;
            state.updateRecipientOtpError = { ...action.payload };
            state.loading = false;
            state.updateRecipientOtpCount = state.updateRecipientOtpCount + 1;
        },
        resetUpdateRecipient: (state) => {
            state.updateRecipientOtpError = {
                status: 200,
                message: '',
            };
            state.updateRecipientSuccess = false;
            state.updateRecipientOtp = false;
            state.updateRecipientOtpCount = 0;
            state.updateRecipientToken = null;
            state.loading = false;
        },

        getDeleteRecipientOtp(state) {
            state.loading = true;
            state.deleteRecipientOtpError = {
                status: false,
                message: '',
            };
        },
        getDeleteRecipientOtpSuccess: (state, action) => {
            state.deleteRecipientOtp = true;
            state.deleteRecipientToken = action.payload?.token;
            state.loading = false;
        },
        getDeleteRecipientOtpError: (state, action) => {
            state.deleteRecipientOtp = false;
            state.deleteRecipientToken = null;
            state.deleteRecipientOtpError = { ...action.payload };
            state.loading = false;
            state.deleteRecipientOtpCount = state.updateRecipientOtpCount + 1;
        },
        resetDeleteRecipient: (state) => {
            state.deleteRecipientOtpError = {
                status: 200,
                message: '',
            };
            state.deleteRecipientSuccess = false;
            state.deleteRecipientOtp = false;
            state.deleteRecipientOtpCount = 0;
            state.deleteRecipientToken = null;
            state.loading = false;
        },

        resetRecipients: () => initialState,
    },
});

export const { actions, reducer, name } = recipientsSlice;
