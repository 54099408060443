import moment from 'moment';
import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slice/reports';
import { request } from 'utils/api';
import Endpoints from 'utils/endpoints';
import { clean } from 'utils/helpers';

const {
    report: { accountTransactions, transactions, masReport },
    accounts: { generateStatement },
} = Endpoints;

export function* getAllTransactions(action) {
    const { account_id, payment_type, status, from_date, currency, to_date } = action.payload;

    const type_filter = payment_type.map((item) => 'payment_type=' + item).join('&');
    const status_filter = status.map((item) => 'status=' + item).join('&');

    const params = {
        from_date,
        to_date,
        page: 1,
        size: 100,
    };
    try {
        let response = null;
        if (account_id) {
            response = yield call(
                request().get,
                accountTransactions.url(account_id, type_filter, status_filter),
                { params }
            );
        } else {
            response = yield call(
                request().get,
                transactions.url(currency, type_filter, status_filter),
                { params }
            );
        }
        const { data } = response;
        const { items, size, total } = data;
        var { page } = data;
        var txn_count = 0,
        txns = [];
        txn_count += size;
        txns = txns.concat(items);
        while (txn_count < total) {
            page += 1;
            const next_page_params = {
                ...params,
                page,
            };
            let response = null;
            if (account_id) {
                response = yield call(
                    request().get,
                    accountTransactions.url(account_id, type_filter, status_filter),
                    { params: next_page_params }
                );
            } else {
                response = yield call(
                    request().get,
                    transactions.url(currency, type_filter, status_filter),
                    { params: next_page_params }
                );
            }
            const { data } = response;
            const { items, size } = data;
            txn_count += size;
            txns = txns.concat(items);
        }
        if (txns) {
            yield put(actions.getAllTransactionsSuccess(txns));
        }
    } catch (err) {
        yield put(actions.getAllTransactionsError(err.message));
    }
}

export function* fetchFromAccount(action) {
    const { url } = Endpoints.accounts.account;
    const account_id = action.payload;
    try {
        const { data } = yield call(request().get, `${url}/${account_id}`);
        if (data) {
            yield put(actions.fetchFromAccountSuccess(data));
        } else {
            yield put(
                actions.fetchFromAccountFailure({
                    error: 'Invalid data',
                })
            );
        }
    } catch (err) {
        const { message } = err.response;
        yield put(
            actions.fetchFromAccountFailure({
                error: message,
            })
        );
    }
}

export function* createAccountStatement(action) {
    const { url } = Endpoints.accounts.account;
    try {
        const { data } = yield call(request().get, `${url}`);
        if (data) {
            yield put(actions.createAccountStatementSuccess(data));
        } else {
            yield put(
                actions.createAccountStatementFailure({
                    error: 'Invalid data',
                })
            );
        }
    } catch (err) {
        const { message } = err.response;
        yield put(
            actions.createAccountStatementFailure({
                error: message,
            })
        );
    }
}

export function* generateMonthlyAccountStatement(action) {
    try {
        const { accountId, month, year } = action.payload;
        const { data } = yield call(request().post, generateStatement.url(accountId), {
            month,
            year,
        });

        if (data) {
            yield put(actions.generateMASSuccess());
        }
    } catch (err) {
        let errMessage =
            'An error was encountered while queuing the MAS. Please retry in a few minutes or contact cs@merge.money if the issue persists.';
        if (err.response?.data?.message) {
            errMessage = err.response.data.message;
        }

        yield put(actions.generateMASError(errMessage));
    }
}

export function* fetchMASReports({ payload }) {
    try {
        let searchParameters = '';

        if (payload) {
            const queryParams = {
                search_requested_by: payload?.username ?? '',
                alias_id: payload?.jobId ?? '',
                search_account: payload?.searchAccount ?? '',
                page: payload?.page ?? 1,
                size: payload?.size ?? 10,
            };
            searchParameters = new URLSearchParams(clean(queryParams));
            payload?.status?.forEach((status) => searchParameters.append('status', status));

            if (payload.requestedDate) {
                searchParameters.set(
                    'from_date',
                    moment(moment(payload.requestedDate).format('YYYY-MM-DD') + 'T00:00:00').unix()
                );
                searchParameters.set(
                    'to_date',
                    moment(moment(payload.requestedDate).format('YYYY-MM-DD') + 'T23:59:59').unix()
                );
            }
        }

        const { data } = yield call(request().get, masReport.url(searchParameters.toString()));

        if (data) {
            yield put(actions.listMASReportsSuccess(data));
        }
    } catch (err) {
        yield put(actions.listMASReportsError());
    }
}

export function* reportsSaga() {
    yield takeLatest(actions.getAllTransactions.type, getAllTransactions);
    yield takeLatest(actions.fetchFromAccount.type, fetchFromAccount);
    yield takeLatest(actions.createAccountStatement.type, createAccountStatement);
    yield takeLatest(actions.generateMonthlyStatement.type, generateMonthlyAccountStatement);
    yield takeLatest(actions.fetchMASReports.type, fetchMASReports);
}
