import React from 'react';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';
import { getInitials } from 'utils/helpers';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styles from 'assets/styles/common.module.scss';
import cx from 'classnames';

//Needs to be wrapped by a parent List component,when used in a parent component
const CustomList = (props) => {
    const {
        name,
        title,
        subtitle,
        avatar,
        subAvatar,
        actions,
        customClass,
        avatarClass,
        collapsibleContent,
        onClick,
        disabled = false,
    } = props;

    const materialTheme = createTheme({
        components: {
            MuiListItemText: {
                styleOverrides: {
                    primary: {
                        fontSize: '16px',
                    },
                    secondary: {
                        fontSize: '14px',
                        width: '70%',
                    },
                },
            },
            MuiListItemSecondaryAction: {
                styleOverrides: {
                    root: {
                        fontSize: '18px',
                        fontWeight: 500,
                    },
                },
            },
        },
    });

    return (
        <React.Fragment>
            <ThemeProvider theme={materialTheme}>
                <ListItem
                    key={name}
                    className={`${customClass} ${disabled ? 'Disabled' : ''}`}
                    onClick={onClick}>
                    {avatar && (
                        <ListItemAvatar style={{ position: 'relative' }}>
                            <Avatar
                                src={avatar}
                                className={`${avatarClass}`}
                                style={{
                                    background: disabled
                                        ? 'rgba(196, 196, 196, 0.5)'
                                        : 'rgb(0, 160, 155, 0.19)',
                                }}>
                                {getInitials(name) ?? 'N/A'}
                            </Avatar>
                            {subAvatar && subAvatar}
                        </ListItemAvatar>
                    )}
                    <ListItemText
                        disableTypography={true}
                        primary={
                            <div>
                                <div className={cx(styles.customListPrimary)}>
                                    {title}
                                </div>
                                {subtitle && (
                                    <div className={cx(styles.customListSecondary)}>
                                        {subtitle}
                                    </div>
                                )}
                            </div>
                        }
                    />
                    {actions}
                </ListItem>
                {collapsibleContent && collapsibleContent}
            </ThemeProvider>
        </React.Fragment>
    );
};

export default CustomList;

CustomList.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    subtitle: PropTypes.string,
    avatar: PropTypes.string,
    actions: PropTypes.node,
    customClass: PropTypes.string,
    avatarClass: PropTypes.string,
    collapsibleContent: PropTypes.node,
    onClick: PropTypes.func,
    icon: PropTypes.string,
};
