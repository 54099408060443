import React, { useCallback, useState } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import cx from 'classnames';
import styles from 'assets/styles/recipients.module.scss';
import { Grid } from '@mui/material';
import { actions } from 'store/slice/recipients';
import selectState from 'store/selectors/recipients';
import { useDispatch } from 'react-redux';
import CustomStepperIcon from 'components/common/StepperContent';
import styled from 'styled-components/macro';
import { Dialog, Icon, Otp } from 'components/common';
import AddBankDetails from './AddBankDetails';
import AddResidenceDetails from './AddResidenceDetails';
import AddRecipientConfirm from './AddRecipientConfirm';
import { StyledOtpButtonWrapper } from 'components/common/Otp/styles';
import DialogV2 from 'components/common/DialogV2';
import { ButtonV2 } from 'components/common/Button';

const steps = ['Bank account details', 'Recipient details', 'Confirm recipient'];

const CustomStepLabel = styled(StepLabel)(({ ownerState }) => ({
    ...(ownerState.completed && {
        color: '#00A09B !important',
    }),
    ...(ownerState.active && {
        color: '#00A09B',
    }),
    ...(ownerState.error && {
        color: '#FF8888 !important',
    }),
}));

const StyledLabel = styled('span')(({ ownerState }) => ({
    ...(ownerState.completed && {
        color: '#00A09B !important',
    }),
    ...(ownerState.active && {
        color: '#00A09B !important',
    }),
}));

const StyledOffsetGrid = styled(Grid)`
    @media (max-width: 768px) {
        display: none;
    }
`;

const AddRecipient = ({ onCancel, onClose }) => {
    const dispatch = useDispatch();

    const [currentStep, setCurrentStep] = useState(0);
    const [recipientDetails, setRecipientDetails] = useState({});

    const [isError, setIsError] = React.useState(false);
    const [isAddRecipientModalOpen, setIsAddRecipientModalOpen] = React.useState(false);
    const [addRecipientOtp, setAddRecipientOtp] = React.useState('');

    const {
        // loading,
        addRecipientSuccess,
        addRecipientOtpError,
        addRecipientOtpCount,
        addRecipientToken,
    } = selectState();

    const isLastStep = currentStep === steps.length - 1;

    const isStepError = (step) => {
        return step === 3;
    };

    const StepIconError = () => {
        return <CustomStepperIcon error={true} />;
    };

    const StepIcon = (props) => {
        const { active, completed, className, error } = props;
        return (
            <CustomStepperIcon
                active={active}
                completed={completed}
                error={error}
                className={className}
                finalStepCompleted={isLastStep}
            />
        );
    };

    const StepIconSuccess = () => {
        return <Icon name="passed" className="completedIcon" />;
    };

    const handleRecipientDetailsUpdate = (values) => {
        setRecipientDetails((prev) => ({
            ...prev,
            ...values,
        }));
    };

    const handleBankDetailsUpdate = (values) => {
        handleRecipientDetailsUpdate(values);
        setCurrentStep(1);
    };

    const handleResidenceDetailsUpdate = (values) => {
        handleRecipientDetailsUpdate(values);
        setCurrentStep(2);
    };

    const handleCreateRecipient = () => {
        setIsAddRecipientModalOpen(true);
        const payload = getRecipientPayload(recipientDetails);
        dispatch(actions.getAddRecipientOtp(payload));
    };

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <AddBankDetails
                        defaultValues={recipientDetails}
                        onSubmit={handleBankDetailsUpdate}
                        onCancel={onCancel}
                    />
                );
            case 1:
                return (
                    <AddResidenceDetails
                        recipient={recipientDetails}
                        onSubmit={handleResidenceDetailsUpdate}
                        onEdit={() => {
                            setCurrentStep(0);
                        }}
                        onCancel={onCancel}
                    />
                );
            case 2:
                return (
                    <AddRecipientConfirm
                        recipient={recipientDetails}
                        onBankDetailsEdit={() => {
                            setCurrentStep(0);
                        }}
                        onResidenceDetailsEdit={() => {
                            setCurrentStep(1);
                        }}
                        onCancel={onCancel}
                        onSubmit={handleCreateRecipient}
                    />
                );
            default:
                return 'Unknown step';
        }
    };

    const getRecipientPayload = useCallback((values) => {
        let filteredValues = Object.fromEntries(
            Object.entries(values).filter(([_, v]) => v !== '')
        );

        if (values.type === 'person') {
            // Delete company name if unintentionally added
            delete filteredValues.company_name;
        } else if (values.type === 'business') {
            // Delete first name and last name if unintentionally added
            delete filteredValues.first_name;
            delete filteredValues.last_name;
        }
        // Add currency from the store
        const address = {
            line1: values.address_line1,
            line2: values.address_line2,
            city: values.address_city,
            state: values.address_state,
            postcode: values.address_postcode,
            country: values.address_country,
        };
        const filteredAddress = Object.fromEntries(
            Object.entries(address).filter(([_, v]) => !!v)
        );

        filteredValues = {
            ...filteredValues,
            ...(Object.keys(filteredAddress).length === 0 ?  ({}): ({recipient_address: filteredAddress})),
        };
        delete filteredValues.address_line1;
        delete filteredValues.address_line2;
        delete filteredValues.address_city;
        delete filteredValues.address_state;
        delete filteredValues.address_postcode;
        delete filteredValues.address_country;
        return filteredValues;
    }, []);

    const submitAddRecipientOtp = useCallback(() => {
        if (addRecipientOtp.length === 6) {
            const payload = getRecipientPayload(recipientDetails);
            dispatch(
                actions.createRecipients({
                    payload,
                    otp: {
                        otp: addRecipientOtp,
                        token: addRecipientToken,
                    },
                })
            );
        } else {
            setIsError(true);
        }
    }, [addRecipientOtp, recipientDetails, dispatch, addRecipientToken, getRecipientPayload]);

    const clearRecipientInfo = useCallback(() => {
        setIsAddRecipientModalOpen(false);
        setAddRecipientOtp('');
        dispatch(actions.resetAddRecipient());
    }, [dispatch]);

    const handleAddRecipientResend = useCallback(() => {
        setAddRecipientOtp('');
        const payload = getRecipientPayload(recipientDetails);
        dispatch(actions.getAddRecipientOtp(payload));
    }, [dispatch, recipientDetails, getRecipientPayload]);

    return (
        <Grid className={cx(styles['add-recipients'])}>
            {isLastStep && <StyledOffsetGrid item md={2} />}
            <Grid item md={12} xs={12} sm={12} lg={12}>
                <Stepper
                    activeStep={currentStep}
                    className={cx(styles['stepper-wrapper'])}
                    alternativeLabel
                    connector={
                        <div className={cx(styles['stepper-connector'])}>
                            <span />
                        </div>
                    }>
                    {steps.map((label, index) => {
                        if (isStepError(index)) {
                            return (
                                <Step key={label}>
                                    <CustomStepLabel
                                        StepIconComponent={StepIconError}
                                        ownerState={{
                                            error: isStepError(index),
                                        }}>
                                        <StyledLabel
                                            ownerState={{
                                                error: isStepError(index),
                                            }}
                                            style={{
                                                color: '#FF8888',
                                            }}>
                                            {label}
                                        </StyledLabel>
                                    </CustomStepLabel>
                                </Step>
                            );
                        }
                        if (isLastStep) {
                            return (
                                <Step key={label}>
                                    <CustomStepLabel
                                        StepIconComponent={StepIconSuccess}
                                        ownerState={{
                                            active: currentStep === index,
                                            error: isStepError(index),
                                            completed: currentStep > index,
                                        }}>
                                        <StyledLabel
                                            ownerState={{
                                                active: currentStep === index,
                                                completed: currentStep > index,
                                            }}>
                                            {' '}
                                            {label}
                                        </StyledLabel>
                                    </CustomStepLabel>
                                </Step>
                            );
                        }
                        return (
                            <Step key={label}>
                                <CustomStepLabel
                                    StepIconComponent={StepIcon}
                                    ownerState={{
                                        active: currentStep === index,
                                        error: isStepError(index),
                                        completed: currentStep > index,
                                    }}>
                                    <StyledLabel
                                        ownerState={{
                                            active: currentStep === index,
                                            completed: currentStep > index,
                                        }}>
                                        {' '}
                                        {label}
                                    </StyledLabel>
                                </CustomStepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Grid>
            {renderStepContent(currentStep)}
            <Dialog
                title={!isError ? 'Authenticate' : 'Failed to add recipient'}
                fullWidth
                open={isAddRecipientModalOpen}
                isSingleButton={false}
                className={'add-recipient'}
                setOpen={() => {
                    clearRecipientInfo();
                }}
                submitButton={null}
                isActions={false}
                backdropProps={{
                    style: {
                        backgroundColor: 'rgba(255,255,255, 0.8)',
                    },
                }}>
                <div className={cx(styles.dialog)}>
                    {!isError ? (
                        <Otp
                            heading={
                                'Enter the one-time passcode sent to the registered mobile number.'
                            }
                            otp={addRecipientOtp}
                            onChange={setAddRecipientOtp}
                            disabled={addRecipientOtp.length !== 6}
                            length={6}
                            onSubmit={submitAddRecipientOtp}
                            onResend={handleAddRecipientResend}
                            error={addRecipientOtpError}
                            buttonText={'Submit'}
                            otpCount={addRecipientOtpCount}
                            maxTime={45}
                            isSecured={true}
                        />
                    ) : (
                        <>
                            <StyledOtpButtonWrapper>
                                <ButtonV2
                                    onClick={() => {
                                        setIsAddRecipientModalOpen(!isAddRecipientModalOpen);
                                    }}
                                    size="lg"
                                    variant="gradient"
                                    text={'Try Again'}
                                />
                            </StyledOtpButtonWrapper>
                        </>
                    )}
                </div>
            </Dialog>
            <DialogV2
                title={'Recipient added'}
                open={addRecipientSuccess}
                isSingleButton={true}
                setOpen={() => {
                    dispatch(actions.resetAddRecipientSuccess());
                    setIsAddRecipientModalOpen(false);
                    onClose();
                }}
                submitAction={() => {
                    dispatch(actions.resetAddRecipientSuccess());
                    setIsAddRecipientModalOpen(false);
                    onClose();
                }}
                submitButton={'Close'}
                variant={'success'}
                className={'recipients-modal'}
            >
                The recipient {recipientDetails.type === 'person' ? `${recipientDetails.first_name} ${recipientDetails.last_name}`: recipientDetails.company_name} is created successfully. You can now send funds to this recipient. 
            </DialogV2>
        </Grid>
    );
};

export default AddRecipient;
