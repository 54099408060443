import moment from 'moment';

export function getPrevMonthDate() {
    return moment().subtract(1, 'months');
}

export function getPrevMonthDateFormat(format) {
    return getPrevMonthDate().format(format);
}

export function getCalendarYears({ startDate, endDate }) {
    if (!startDate || !endDate) return [];

    const calendarYearsArr = [];

    for (let year = startDate.year(); year <= endDate.year(); year++) {
        calendarYearsArr.push(String(year));
    }

    return calendarYearsArr.reverse();
}

export function getCalendarYearMonths({ startDate, endDate, year }) {
    if (!startDate || !endDate) return [];

    const calendarListArr = [];
    const startOfYear = moment([year, 0, 1]);
    const endOfYear = moment([year, 11, 31]);
    const adjustedStartDate = moment.max(startDate, startOfYear);
    const adjustedEndDate = moment.min(endDate, endOfYear);

    let currentDate = adjustedStartDate.clone().startOf('month');
    const finalMonth = adjustedEndDate.clone().startOf('month');

    while (currentDate.isBefore(adjustedEndDate) && currentDate.isSameOrBefore(finalMonth)) {
        calendarListArr.push({
            month: currentDate.format('MMMM'),
            monthIndex: currentDate.month(),
        });
        currentDate.add(1, 'month');
    }

    return calendarListArr.sort((a, b) => b.monthIndex - a.monthIndex).map(({ month }) => month);
}
