export const getAccountStatusIcon = (status) => {
    switch (status) {
        case 'in-progress':
            return 'account-status-pending';
        case 'close_initiated': // fall through
        case 'active':
            return 'account-status-active';
        case 'closed': // fall through
        case 'create-failed': // fall through
            return 'account-status-closed';
        case 'blocked':
            return 'account-status-blocked';
        default:
            return '';
    }
};

export const getAccountStatusDescription = (status) => {
    switch (status) {
        case 'in-progress':
            return 'In-progress: The account creation is in progress.';
        case 'close_initiated': // fall through
        case 'active':
            return 'Active: This account is operational.';
        case 'closed': // fall through
        case 'create-failed': // fall through
            return 'Closed: This account is closed.';
        case 'blocked':
            return 'Blocked: This account is blocked.';
        default:
            return '';
    }
};

export const getInitialFundValue = (currencySymbol) => ({
    amountStr: `${currencySymbol} 99`,
    amount: '99',
});
