import styles from './button.module.scss';
import cx from 'classnames';
import PropTypes from "prop-types";

const ButtonV2 = (props) => {
    const {
        text,
        onClick,
        disabled = false,
        variant = 'primary',
        size = 'sm',
    } = props;
    return (
        <div
            className={cx([styles['buttonV2'], styles['btn-'+variant], styles['btn-size-' + size] ])}
            onClick={!disabled ? onClick: () => {}}
            disabled={disabled}
        >
            {text}
        </div>
    );
};

export default ButtonV2;

ButtonV2.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    variant: PropTypes.string,
    hasIcon: PropTypes.bool,
    iconName: PropTypes.string,
    fullWidth: PropTypes.bool,
    type: PropTypes.string,
};
