import React from 'react';
import { ResendOTP } from 'otp-input-react';
import { StyledOtpWrapper, StyledOtpInput, StyledOtpError, StyledOtpButtonWrapper } from './styles';
import PropTypes from 'prop-types';
import { Loading } from 'components/common';
import styles from 'assets/styles/common.module.scss';
import cx from 'classnames';
import { ButtonV2 } from '../Button';

const renderRemainingTime = (buttonProps) => {
    const { onClick } = buttonProps;
    return (
        <div className="otp-resend">
            {buttonProps.remainingTime !== 0 ? (
                <>
                    Didn't get code? <span className="resend-button">Resend</span> in:{' '}
                    {buttonProps.remainingTime}
                </>
            ) : (
                <div
                    className="resend-button"
                    onClick={() => onClick()}
                    style={{ cursor: 'pointer' }}>
                    Resend Code
                </div>
            )}
        </div>
    );
};

const Otp = ({
    otp,
    onChange,
    disabled,
    length,
    heading,
    onSubmit,
    onResend,
    error,
    otpCount,
    maxTime,
    buttonText,
    isSecured = false,
    loading = false,
    addOnStyles = '',
}) => {
    const renderTime = () => React.Fragment;
    return (
        <StyledOtpWrapper>
            {loading && <Loading className={cx(styles.otpLoading, addOnStyles)} />}
            <p>{heading}</p>
            <StyledOtpInput
                value={otp}
                onChange={onChange}
                autoFocus
                OTPLength={length}
                otpType="number"
                className={'otp-input'}
                error={error}
                maxTime={maxTime}
                secure={isSecured}
                id={'outlined-basic_otp'}
            />
            <StyledOtpError>
                {(error.message === 'Invalid OTP' || error?.message === 'OTP missmatch') &&
                    otpCount !== 3 &&
                    'The one-time password validation failed. Please re-type the OTP or generate a new OTP by clicking Resend.'}
                {error?.message === 'Gone' &&
                    otpCount !== 3 &&
                    'OTP expired. Please use resend code to get new one.'}
                {error.message === 'Invalid OTP' &&
                    otpCount === 3 &&
                    'The maximum number of attempts have been made. Please resend the code'}
            </StyledOtpError>
            <StyledOtpButtonWrapper>
                <ButtonV2
                    disabled={disabled}
                    onClick={onSubmit}
                    size="lg"
                    text={buttonText ? buttonText : 'Submit'}
                />
            </StyledOtpButtonWrapper>
            <ResendOTP
                style={{justifyContent: 'center'}}
                renderButton={renderRemainingTime}
                onResendClick={onResend}
                renderTime={renderTime}
                maxTime={maxTime}
            />
        </StyledOtpWrapper>
    );
};

export default Otp;

Otp.propTypes = {
    otp: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    length: PropTypes.number,
    heading: PropTypes.string,
    onSubmit: PropTypes.func,
    onResend: PropTypes.func,
    otpCount: PropTypes.number,
};
