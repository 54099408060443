import { Avatar, Box, Grid } from '@mui/material';
import styles from 'assets/styles/account.module.scss';
import cx from 'classnames';
import { CustomTooltip, Icon, Loading } from 'components/common';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAuthData, getUserTypes } from 'store/selectors/auth';
import { AllowedUsers, belongsTo } from 'utils/auth';
import {
    accountFeatureFlag,
    disableAccountCreatedInCurrentMonth,
    formatAmount,
    getCurrencyDescription,
    getCurrencySymbol,
    getInitials,
    isQuoteEnabled,
    toCapitalize,
} from 'utils/helpers';
import { getAccountStatusIcon, getAccountStatusDescription } from './utils';
import { isProd } from 'utils/helpers';
import { actions } from 'store/slice/account';
import selectState from 'store/selectors/account';
import AddFundsModal from './AddFundsModal';
import CurrencyIcon from 'components/common/CurrencyIcon';
import NumberFormat from 'react-number-format';
import selectAppState from 'AppSelectors';
import { MAIN_ACCOUNT, MODAL_TYPE } from './constants';
import CreateSubAccount from './CreateSubAccount';
import UpdateAccount from './UpdateAccount';
import CloseAccount from './CloseSubAccount';

const AccountsSummary = () => {
    const { featureFlags } = selectAppState();
    const navigate = useNavigate();
    const userTypes = useSelector(getUserTypes);
    const dispatch = useDispatch();
    const [displayModal, setDisplayModal] = useState('');
    const [accountMeta, setAccountMeta] = useState({
        accountId: '',
        currency: '',
    });
    const { loading, error, summary } = selectState();
    const { login } = useSelector(getAuthData);
    const isValidCompany = isProd && login.company_id === '281a5449-5d06-4de5-a4f7-f3ed9ef56c93';

    useEffect(() => {
        dispatch(actions.getAccountsSummary());
    }, [dispatch]);

    const handleRefresh = useCallback(() => {
        if (!loading) {
            dispatch(actions.getAccountsSummary());
        }
    }, [dispatch, loading]);

    const copyAccount = useCallback((account) => {
        var toCopy = account.account_name + '\n';
        if (account.account_number) {
            toCopy += `Account number: ${account?.account_number}\n`;
        }
        if (account.sort_code) {
            toCopy += `Sort code: ${account?.sort_code}\n`;
        }
        if (account.iban) {
            toCopy += `IBAN: ${account?.iban}\n`;
        }
        if (account.bic) {
            toCopy += `BIC: ${account?.bic}\n`;
        }
        return toCopy.trim();
    }, []);

    const onModalClose = (val = false) => setDisplayModal(val);

    return (
        <Grid container className={cx(styles.container)}>
            <div className={cx(styles.header)}>
                <h1 className={cx(styles.title)}>Accounts</h1>
                <Icon name={'refresh'} onClick={() => handleRefresh()} />
            </div>
            {loading ? (
                <Loading className={cx(styles.loading)} />
            ) : (
                summary?.currencies?.map((account) => (
                    <Grid container key={account.currency}>
                        <Grid item sm={12} className={cx(styles.currencyDetails)}>
                            <div className={cx(styles.currencyHeader)}>
                                <div className={cx(styles.currencyTitle)}>
                                    <CustomTooltip info={account.currency}>
                                        <div className={cx(styles.accountName)}>
                                            <CurrencyIcon
                                                rounded
                                                style={{
                                                    height: 'auto',
                                                    width: '30px',
                                                }}
                                                currency={account.currency}
                                            />
                                            &nbsp;
                                            {getCurrencyDescription(account.currency)}
                                        </div>
                                    </CustomTooltip>
                                    <CustomTooltip info={'Refresh details'}>
                                        <Icon name={'refresh'} onClick={handleRefresh} />
                                    </CustomTooltip>
                                </div>
                                <div className={cx(styles.cumulativeBalance)}>
                                    <div className={cx(styles.totalBalance)}>
                                        <NumberFormat
                                            value={formatAmount(account.total_available_balance)}
                                            thousandSeparator={true}
                                            displayType={'text'}
                                            decimalScale={2}
                                            prefix={getCurrencySymbol(account.currency)}
                                        />
                                    </div>
                                    <div
                                        className={cx(styles.viewDetails)}
                                        onClick={() => {
                                            navigate(`/dashboard/accounts/${account.currency}`);
                                        }}>
                                        View details <Icon name={'chevron'} />
                                    </div>
                                </div>
                            </div>
                            <div className={cx(styles.snapShot)}>
                                {account.top_accounts.map((t) => (
                                    <div key={t.id} className={cx(styles.detail)}>
                                        <div className={cx(styles.summaryPanel)}>
                                            <div style={{ position: 'relative' }}>
                                                <Avatar className={cx(styles.avatar)}>
                                                    {getInitials(
                                                        t.client_account_name || t.account_name
                                                    )}
                                                </Avatar>
                                                <Box className={cx(styles.avatarSubIconSummary)}>
                                                    <CustomTooltip
                                                        info={getAccountStatusDescription(
                                                            t.status
                                                        )}>
                                                        <Icon
                                                            name={getAccountStatusIcon(t.status)}
                                                            width="15px"
                                                            height="15px"
                                                        />
                                                    </CustomTooltip>
                                                </Box>
                                            </div>
                                            <div className={cx(styles.clientName)}>
                                                {toCapitalize(
                                                    t.client_account_name || t.account_name
                                                )}
                                            </div>
                                            <div className={cx(styles.bankDetails)}>
                                                *
                                                {t.iban?.substring(t.iban.length - 4) ||
                                                    t.account_number?.substring(
                                                        t.account_number.length - 4
                                                    )}
                                            </div>
                                            {t.type === MAIN_ACCOUNT && (
                                                <div className={cx(styles.tagContainer)}>
                                                    <div className={cx(styles.typeTag)}>Main</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className={cx(styles.summaryBalance)}>
                                            <div className={styles.actions}>
                                                <div className={cx(styles.accBalance)}>
                                                    <NumberFormat
                                                        value={formatAmount(t.balance)}
                                                        thousandSeparator={true}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        prefix={getCurrencySymbol(t.currency)}
                                                    />
                                                </div>
                                                <div
                                                    className={cx(styles.actionsIcon)}
                                                    disabled={t.status === 'in-progress'} // Enable view transactions for other than pending accounts
                                                    onClick={() =>
                                                        navigate({
                                                            pathname: `/dashboard/accounts/${t.id}/transactions`,
                                                        })
                                                    }>
                                                    <CustomTooltip info={'View Transactions'}>
                                                        <Icon name={'transactions'} />
                                                    </CustomTooltip>
                                                </div>
                                                <div
                                                    className={cx(styles.actionsIcon)}
                                                    disabled={
                                                        !belongsTo(
                                                            userTypes,
                                                            AllowedUsers.SendFunds
                                                        ) ||
                                                        t.status !== 'active' ||
                                                        parseFloat(t.balance) === 0
                                                    }
                                                    onClick={() =>
                                                        navigate({
                                                            pathname: `/dashboard/funds`,
                                                            search: `?from=${t.id}`,
                                                        })
                                                    }>
                                                    <CustomTooltip info={'Send funds'}>
                                                        <Icon
                                                            name={
                                                                isQuoteEnabled(t)
                                                                    ? 'send-exchange'
                                                                    : 'send'
                                                            }
                                                        />
                                                    </CustomTooltip>
                                                </div>
                                                <div
                                                    className={cx(styles.actionsIcon)}
                                                    disabled={t.status === 'in-progress'}
                                                    onClick={() =>
                                                        navigate({
                                                            pathname: `/dashboard/pending-payments`,
                                                            search: `?search_account=${
                                                                t.iban ?? t.account_number
                                                            }&currency=${t.currency}`,
                                                        })
                                                    }>
                                                    <CustomTooltip info={'Pending payments'}>
                                                        <Icon name={'approve-payments'} />
                                                    </CustomTooltip>
                                                </div>
                                                <div
                                                    className={cx(styles.actionsIcon)}
                                                    disabled={
                                                        !belongsTo(
                                                            userTypes,
                                                            AllowedUsers.MakePaymentRequest
                                                        ) ||
                                                        t.status !== 'active' ||
                                                        parseFloat(t.balance) === 0
                                                    }
                                                    onClick={() =>
                                                        navigate({
                                                            pathname: `/dashboard/payment-request`,
                                                            search: `?from=${t.id}`,
                                                        })
                                                    }>
                                                    <CustomTooltip info={'Queue payments'}>
                                                        <Icon name={'payment-request'} />
                                                    </CustomTooltip>
                                                </div>
                                                <div
                                                    className={cx(styles.actionsIcon)}
                                                    disabled={
                                                        !belongsTo(
                                                            userTypes,
                                                            AllowedUsers.Reports
                                                        ) || t.status === 'in-progress'
                                                    }
                                                    onClick={() =>
                                                        navigate({
                                                            pathname: `/dashboard/transaction-report`,
                                                            search: `?from=${t.id}`,
                                                        })
                                                    }>
                                                    <CustomTooltip info={'Transaction reports'}>
                                                        <Icon name={'reports-icon'} />
                                                    </CustomTooltip>
                                                </div>
                                                {(isValidCompany || !isProd) &&
                                                    featureFlags.monthly_account_statement_enabled && (
                                                        <div
                                                            className={cx(styles.actionsIcon)}
                                                            disabled={
                                                                !belongsTo(
                                                                    userTypes,
                                                                    AllowedUsers.Reports
                                                                ) ||
                                                                t.status === 'in-progress' ||
                                                                disableAccountCreatedInCurrentMonth(
                                                                    t
                                                                )
                                                            }
                                                            onClick={() =>
                                                                navigate({
                                                                    pathname: `/dashboard/account-statement`,
                                                                    search: `?from=${t.id}`,
                                                                })
                                                            }>
                                                            <CustomTooltip
                                                                info={'Monthly account statement'}>
                                                                <Icon name={'monthly-statement'} />
                                                            </CustomTooltip>
                                                        </div>
                                                    )}
                                                <div
                                                    className={cx(styles.actionsIcon)}
                                                    disabled={t.status === 'in-progress'}
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(
                                                            copyAccount(t)
                                                        );
                                                    }}>
                                                    <CustomTooltip
                                                        info={'Copy bank account details'}>
                                                        <Icon
                                                            name={'copy'}
                                                            className={cx(styles.copyIcon)}
                                                        />
                                                    </CustomTooltip>
                                                </div>
                                                {!isProd && (
                                                    <div
                                                        className={cx(styles.actionsIconSandbox)}
                                                        onClick={() => {
                                                            setDisplayModal(MODAL_TYPE.AddFunds);
                                                            setAccountMeta({
                                                                accountId: t.id,
                                                                currency: t.currency,
                                                            });
                                                        }}
                                                        disabled={
                                                            !belongsTo(
                                                                userTypes,
                                                                AllowedUsers.AddFunds
                                                            ) || t.status !== 'active'
                                                        }>
                                                        <CustomTooltip info={'Add funds'}>
                                                            <Icon name={'add-funds'} />
                                                        </CustomTooltip>
                                                    </div>
                                                )}
                                                {featureFlags.create_account_enabled &&
                                                    accountFeatureFlag(
                                                        t,
                                                        'linked_account_creation_enabled',
                                                        true
                                                    ) && (
                                                        <div
                                                            className={cx(styles.actionsIcon)}
                                                            onClick={() => {
                                                                setDisplayModal(
                                                                    MODAL_TYPE.CreateSubAccount
                                                                );
                                                                setAccountMeta({
                                                                    currency: t.currency,
                                                                    accountId: t.id,
                                                                    accountName: t.account_name,
                                                                });
                                                            }}
                                                            disabled={
                                                                !belongsTo(
                                                                    userTypes,
                                                                    AllowedUsers.AccountAdmin
                                                                ) || t.status !== 'active'
                                                            }>
                                                            <CustomTooltip info={'Create account'}>
                                                                <Icon
                                                                    name={
                                                                        MODAL_TYPE.CreateSubAccount
                                                                    }
                                                                />
                                                            </CustomTooltip>
                                                        </div>
                                                    )}
                                                {featureFlags.update_account_enabled && (
                                                    <div
                                                        className={cx(styles.actionsIcon)}
                                                        onClick={() => {
                                                            setDisplayModal(
                                                                MODAL_TYPE.UpdateAccount
                                                            );
                                                            setAccountMeta({
                                                                accountId: t.id,
                                                                accountName:
                                                                    t.client_account_name ||
                                                                    t.account_name,
                                                            });
                                                        }}
                                                        disabled={
                                                            !belongsTo(
                                                                userTypes,
                                                                AllowedUsers.AccountAdmin
                                                            ) || t.status !== 'active'
                                                        }>
                                                        <CustomTooltip info={'Update account'}>
                                                            <Icon name={MODAL_TYPE.UpdateAccount} />
                                                        </CustomTooltip>
                                                    </div>
                                                )}
                                                {featureFlags.close_account_enabled &&
                                                    accountFeatureFlag(
                                                        t,
                                                        'account_closure_enabled',
                                                        true
                                                    ) && (
                                                        <div
                                                            className={cx(
                                                                styles.actionsIconWarning
                                                            )}
                                                            onClick={() => {
                                                                setDisplayModal(
                                                                    MODAL_TYPE.CloseAccount
                                                                );
                                                                setAccountMeta({
                                                                    accountId: t.id,
                                                                    accountName:
                                                                        t.client_account_name ||
                                                                        t.account_name,
                                                                    balance: t.balance,
                                                                });
                                                            }}
                                                            disabled={
                                                                !belongsTo(
                                                                    userTypes,
                                                                    AllowedUsers.AccountAdmin
                                                                ) || t.status !== 'active'
                                                            }>
                                                            <CustomTooltip info={'Close account'}>
                                                                <Icon
                                                                    name={MODAL_TYPE.CloseAccount}
                                                                />
                                                            </CustomTooltip>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Grid>
                    </Grid>
                ))
            )}
            <AddFundsModal
                displayModal={displayModal === MODAL_TYPE.AddFunds}
                accountMeta={accountMeta}
                handleModal={onModalClose}
            />
            {featureFlags.create_account_enabled && (
                <CreateSubAccount
                    displayModal={displayModal === MODAL_TYPE.CreateSubAccount}
                    accountMeta={accountMeta}
                    handleModal={onModalClose}
                    pageType="summary"
                />
            )}
            {featureFlags.update_account_enabled && (
                <UpdateAccount
                    displayModal={displayModal === MODAL_TYPE.UpdateAccount}
                    accountMeta={accountMeta}
                    handleModal={onModalClose}
                    pageType="summary"
                />
            )}
            {featureFlags.close_account_enabled && (
                <CloseAccount
                    displayModal={displayModal === MODAL_TYPE.CloseAccount}
                    accountMeta={accountMeta}
                    handleModal={onModalClose}
                    pageType="summary"
                />
            )}
            {error?.message && !loading && (
                <div className={cx(styles.empty, styles.error)}>
                    <p>Something went wrong. Please try again later.</p>
                </div>
            )}
        </Grid>
    );
};

export default AccountsSummary;
