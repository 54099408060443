import React, { useEffect, useCallback, useState } from 'react';
import { Chip, Grid } from '@mui/material';
import cx from 'classnames';
import styles from 'assets/styles/developer.module.scss';
import { useDispatch } from 'react-redux';
import selectCompanyState from 'store/selectors/company';
import selectState from 'store/selectors/developer';
import { actions } from 'store/slice/developer';
import { Button, CustomTooltip, Dialog, Icon } from 'components/common';
import { StyledOtpButtonWrapper } from 'components/common/Otp/styles';
import { Otp } from 'components/common';
import MuiAlert from '@mui/material/Alert';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import { Divider } from '@mui/material';
import DialogV2 from 'components/common/DialogV2';
import { ButtonV2 } from 'components/common/Button';

const ApiKey = () => {
    const dispatch = useDispatch();
    const {
        apiKey,
        createdKey,
        deactivateKeyStatus,
        otpError,
        deactivateOtpError,
        otpCount,
        deactivateOtpCount,
        deactivateToken,
        token,
    } = selectState();
    const { company } = selectCompanyState();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isDeactivateModalOpen, setIsDeactivateModalOpen] = React.useState(false);
    const [isDeactivateOtpModalOpen, setIsDeactivateOtpModalOpen] = React.useState(false);
    const [OTP, setOTP] = React.useState('');
    const [deactivateOtp, setDeactivateOtp] = React.useState('');
    const [isError, setIsError] = React.useState(false);
    const [copy, setCopy] = useState({
        value: '',
        copied: false,
    });
    const [maskApiKey, setMaskApiKey] = useState(true);

    const toggleShowApiKey = useCallback(() => {
        setMaskApiKey(!maskApiKey);
    }, [maskApiKey]);

    const maskString = (key) => {
        const keyLength = key.length;
        const maskPrefix = key.substr(0, 3);
        const maskSuffix = key.substr(keyLength - 3);
        return maskPrefix + '*'.repeat(keyLength - 6) + maskSuffix;
    };

    useEffect(() => {
        if (!createdKey?.token) {
            return;
        }
        setCopy((val) => ({ ...val, value: createdKey.token }));
    }, [createdKey]);

    useEffect(() => {
        dispatch(actions.getApiKeys());
    }, [dispatch]);

    const getOTP = useCallback(() => {
        dispatch(actions.getOtp({ company }));
    }, [dispatch, company]);

    const getDeactivateOtp = useCallback(() => {
        dispatch(actions.getDeactivateOtp({ apiKeyId: apiKey?.id }));
    }, [dispatch, apiKey?.id]);

    const clearKeys = () => {
        setIsModalOpen(!isModalOpen);
        setOTP('');
        dispatch(actions.clearData());
        dispatch(actions.getApiKeys());
    };

    const clearDeactivateKeys = useCallback(() => {
        setIsDeactivateOtpModalOpen(!isDeactivateOtpModalOpen);
        setDeactivateOtp('');
        dispatch(actions.clearData());
        dispatch(actions.getApiKeys());
    }, [dispatch, isDeactivateOtpModalOpen]);

    const submitOTP = useCallback(() => {
        if (OTP.length === 6) {
            dispatch(actions.createApiKey({ OTP, token, company }));
        } else {
            setIsError(true);
        }
    }, [OTP, token, dispatch, company]);

    const submitDeactivateOTP = useCallback(() => {
        if (deactivateOtp.length === 6) {
            dispatch(
                actions.deactivateApiKey({
                    deactivateOtp,
                    deactivateToken,
                    apiKeyId: apiKey?.id,
                })
            );
        } else {
            setIsError(true);
        }
    }, [deactivateOtp, deactivateToken, apiKey?.id, dispatch]);

    const handleResend = useCallback(() => {
        setOTP('');
        dispatch(actions.getOtp({ company }));
    }, [dispatch, company]);

    const handleDeactivateResend = useCallback(() => {
        setDeactivateOtp('');
        dispatch(actions.getDeactivateOtp({ apiKeyId: apiKey?.id }));
    }, [dispatch, apiKey?.id]);

    useEffect(() => {
        if (!deactivateKeyStatus) {
            return;
        }
        if (deactivateKeyStatus === 'success') {
            setIsDeactivateOtpModalOpen(false);
            clearDeactivateKeys();
            dispatch(actions.getApiKeys());
        }
    }, [dispatch, clearDeactivateKeys, deactivateKeyStatus]);

    return (
        <Grid container className={cx(styles.container)}>
            <Grid md={12} item style={{ width: '100%' }}>
                <h3 className={cx(styles.title)}>API Key</h3>
            </Grid>
            <Grid container className={cx(styles.description)}>
                <div>
                    Each API request must be authenticated with this API key. Below, you can
                    generate an API key for your Company. The API key must be treated as a secret
                    like any other password. Make sure you copy your API key once it is generated.
                    If the API key is lost, it cannot be recovered, and you must generate a new key.
                </div>
            </Grid>
            <Grid container className={cx(styles.divider)}>
                <Divider sx={{ width: '100%' }} />
            </Grid>

            <Grid container className={cx(styles.apiKey)}>
                {!apiKey ? (
                    <div className={cx(styles.noData)}>
                        <Icon name={'api-key'} color={'#C4C4C4'} />
                        <span>The API key is not generated.</span>
                    </div>
                ) : (
                    <div className={cx(styles.data)}>
                        <div className={cx(styles.datacol)}>
                            <div className={cx(styles.header)}>API key status</div>
                            <div>
                                <Chip
                                    sx={{
                                        backgroundColor:
                                            apiKey.status === 'active'
                                                ? 'rgba(87, 173, 158, 0.1)'
                                                : 'rgba(255, 136, 136, 0.1)',
                                        whiteSpace: 'normal',
                                        '& .MuiChip-label': {
                                            color:
                                                apiKey.status === 'active' ? '#57AD9E' : 'rgb(255, 148, 49)',
                                            fontSize: '14px !important',
                                            fontWeight: '500 !important',
                                            lineHeight: '18px',
                                        },
                                    }}
                                    size="small"
                                    icon={
                                        <Icon
                                            name={
                                                apiKey.status === 'active'
                                                    ? 'success-circle'
                                                    : 'expired'
                                            }
                                            size="15px"
                                        />
                                    }
                                    label={apiKey.status === 'active' ? 'Active' : 'Expired'}
                                />
                            </div>
                        </div>
                        <div className={cx(styles.datacol)}>
                            <div className={cx(styles.header)}>API key created on</div>
                            <div className={cx(styles.value)}>
                                {moment(apiKey.created_at).format('DD MMM YYYY HH:mm:ss')}
                            </div>
                        </div>
                        <div className={cx(styles.datacol)}>
                            <div className={cx(styles.header)}>
                                API key {apiKey.status === 'active' ? 'expires on' : 'expired on'}
                            </div>
                            <div className={cx(styles.value)}>
                                {moment(apiKey.expiration).format('DD MMM YYYY HH:mm:ss')}
                            </div>
                        </div>
                        {apiKey.status === 'active' && (
                            <div className={cx(styles.datacol)}>
                                <ButtonV2
                                    variant='tertiary'
                                    text="Deactivate"
                                    className={cx(styles.deactivateBtn)}
                                    onClick={() => {
                                        setIsDeactivateModalOpen(true);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}
            </Grid>
            <Grid container className={cx(styles.divider)}>
                <Divider sx={{ width: '100%' }} />
            </Grid>

            <Grid container className={cx(styles.generateKey)}>
                <CustomTooltip
                    info={
                        apiKey?.status !== 'active'
                            ? 'Click to generate API key'
                            : 'Deactivate the existing token to create new one'
                    }>
                    <ButtonV2
                        variant="secondary"
                        text="+ Generate API Key"
                        size="lg"
                        // className={cx(styles.createKeyBtn)}
                        disabled={apiKey?.status === 'active'}
                        onClick={() => {
                            setIsModalOpen(true);
                            getOTP();
                        }}
                    />
                </CustomTooltip>
            </Grid>
            <DialogV2
                    title={'Deactivate API key'}
                    open={isDeactivateModalOpen}
                    setOpen={() => setIsDeactivateModalOpen(false)}
                    fullWidth
                    cancelButton={`Cancel`}
                    submitButton={'Deactivate'}
                    submitAction={() => {
                        setIsDeactivateModalOpen(false);
                        setIsDeactivateOtpModalOpen(true);
                        getDeactivateOtp();
                    }}
                    cancelAction={() => setIsDeactivateModalOpen(false)}
                    variant={'warning'}
                    className={'deactivate-key-modal'}>
                    You are about to deactivate your current API key. Your API requests using this API Key will stop working immediately.
            </DialogV2>

            <Dialog
                title={!isError ? 'Authenticate' : 'Failed to deactivate API Key'}
                fullWidth
                open={isDeactivateOtpModalOpen}
                isSingleButton={false}
                className={'api-key-modal'}
                setOpen={() => {
                    clearDeactivateKeys();
                }}
                submitButton={null}
                isActions={false}
                backdropProps={{
                    style: {
                        backgroundColor: 'rgba(255,255,255, 0.8)',
                    },
                }}>
                <div className={cx(styles.dialog)}>
                    {!isError ? (
                        <Otp
                            heading={'Enter the one-time passcode sent to the registered email.'}
                            otp={deactivateOtp}
                            onChange={setDeactivateOtp}
                            disabled={deactivateOtp.length !== 6}
                            length={6}
                            onSubmit={() => submitDeactivateOTP()}
                            onResend={handleDeactivateResend}
                            error={deactivateOtpError}
                            buttonText={'Submit'}
                            otpCount={deactivateOtpCount}
                            maxTime={45}
                            isSecured={true}
                        />
                    ) : (
                        <>
                            <StyledOtpButtonWrapper>
                                <ButtonV2
                                    onClick={() => {
                                        setIsDeactivateOtpModalOpen(!isDeactivateOtpModalOpen);
                                    }}
                                    size="lg"
                                    text={'Try Again'}
                                />
                            </StyledOtpButtonWrapper>
                        </>
                    )}
                </div>
            </Dialog>

            <Dialog
                title={
                    !isError
                        ? !createdKey
                            ? 'Authenticate'
                            : 'New API key'
                        : 'Failed to create API Key'
                }
                fullWidth
                open={isModalOpen}
                isSingleButton={false}
                className={'api-key-modal'}
                setOpen={() => {
                    clearKeys();
                }}
                submitButton={null}
                isActions={false}
                backdropProps={{
                    style: {
                        backgroundColor: 'rgba(255,255,255, 0.8)',
                    },
                }}>
                <div className={cx(styles.dialog)}>
                    {!isError ? (
                        !createdKey ? (
                            <Otp
                                heading={'Enter the one-time passcode sent to the registered email.'}
                                otp={OTP}
                                onChange={setOTP}
                                disabled={OTP.length !== 6}
                                length={6}
                                onSubmit={() => submitOTP()}
                                onResend={handleResend}
                                error={otpError}
                                buttonText={'Submit'}
                                otpCount={otpCount}
                                maxTime={45}
                                isSecured={true}
                            />
                        ) : (
                            <div className={cx(styles.newKey)}>
                                <div className={cx(styles.info)}>
                                    Make sure you copy your API key once it is generated. If the API
                                    key is lost, it cannot be recovered, and you must generate a new
                                    key.
                                </div>
                                <MuiAlert
                                    color={'error'}
                                    icon={false}
                                    className={cx(styles.warningInfo)}>
                                    {' '}
                                    Copy the API key now and store it safely. You cannot copy this
                                    API key after closing the window!
                                </MuiAlert>
                                <div className={cx(styles.keyHolder)}>
                                    <span className={cx(styles.key)}>
                                        {maskApiKey
                                            ? maskString(createdKey.token)
                                            : createdKey.token}
                                    </span>
                                    <Icon
                                        name={maskApiKey ? 'show-password' : 'hide-password'}
                                        className={cx(styles.showKey)}
                                        onClick={toggleShowApiKey}
                                    />
                                    <CopyToClipboard text={copy.value} className={cx(styles.icon)}>
                                        <Icon
                                            name={'colored-copy'}
                                            className={cx(styles.copyIcon)}
                                            onClick={() =>
                                                setCopy((val) => ({
                                                    ...val,
                                                    copied: true,
                                                }))
                                            }
                                        />
                                    </CopyToClipboard>
                                </div>
                                <Button
                                    text="I have copied the API Key. Close the window"
                                    className={cx(styles.actionBtn)}
                                    onClick={() => {
                                        clearKeys();
                                    }}
                                />
                            </div>
                        )
                    ) : (
                        <>
                            <StyledOtpButtonWrapper>
                                <ButtonV2
                                    onClick={() => {
                                        setIsModalOpen(!isModalOpen);
                                    }}
                                    size="lg"
                                    text={'Try Again'}
                                />
                            </StyledOtpButtonWrapper>
                        </>
                    )}
                </div>
            </Dialog>
        </Grid>
    );
};

export default ApiKey;
