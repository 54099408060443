import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import Icon from '../Icon';
import CustomTooltip from '../CustomTooltip';
import styles from 'assets/styles/common.module.scss';
import SelectField from './SelectField';

export default function SelectMonth({
    name,
    label,
    required,
    monthOptions,
    yearOptions,
    defaultCalendar,
    info,
    isLoaded,
    onDateChange,
    error,
    disable,
}) {
    const initCalendar = useMemo(
        () => ({
            year: '',
            month: '',
        }),
        []
    );
    const [calendar, setCalendar] = useState(initCalendar);

    const onChange = (fieldName, val) => {
        setCalendar((state) => {
            let newCalendarState = { ...state, [fieldName]: val };
            const stateVal = state[fieldName];

            if (stateVal === val) return state;

            if (fieldName === 'year') {
                newCalendarState = { year: val, month: '' };
            }

            onDateChange(name, newCalendarState);

            return newCalendarState;
        });
    };

    useEffect(() => {
        setCalendar(() => {
            if (isLoaded) {
                const isMonthAvailable = monthOptions?.some(
                    ({ value }) => value === defaultCalendar.month
                );

                if (isMonthAvailable) {
                    return defaultCalendar;
                }
            }

            return initCalendar;
        });
    /* eslint-disable */
    }, [isLoaded, setCalendar]);

    return (
        <div className={cx(styles.selectPicker)}>
            {label && (
                <div className={cx(styles.label)}>
                    {label}
                    {required && <span className={cx(styles.asterisk)}> *</span>}
                    {info && (
                        <CustomTooltip info={info}>
                            <Icon name={'info'} />
                        </CustomTooltip>
                    )}
                </div>
            )}
            <div
                className={cx([
                    error ? styles.errorBorder : styles.default,
                    styles.calendarSelect,
                ])}>
                <SelectField
                    name="year"
                    value={calendar.year}
                    placeHolderText={calendar.year}
                    onChange={onChange}
                    options={yearOptions}
                    className={styles.year}
                    hasLabel={false}
                    selectClassName={styles.selectBox}
                    disable={disable}
                    allowDeselect={false}
                />
                <SelectField
                    name="month"
                    value={calendar.month}
                    placeHolderText={calendar.month}
                    onChange={onChange}
                    options={monthOptions}
                    className={styles.month}
                    hasLabel={false}
                    selectClassName={styles.selectBox}
                    disable={disable}
                    allowDeselect={false}
                />
            </div>
        </div>
    );
}
