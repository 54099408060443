import React from 'react';
import { CustomTooltip, Icon } from 'components/common';
import cx from 'classnames';
import styles from 'assets/styles/recipients.module.scss';
import { Country } from 'country-state-city';
import { toCapitalize } from 'utils/helpers';

const ReviewResidenceDetails = ({ recipient, onEdit }) => {
    const countries = Country.getAllCountries();
    const recipientCountry = countries.find(
        (country) => country.isoCode === recipient.address_country
    );
    const recipientAddressConcat = [
        recipient.address_line1,
        recipient.address_line2,
        recipient.address_city,
        recipient.address_state,
        recipient.address_postcode,
        recipientCountry?.name,
    ]
        .filter((e) => e)
        .join(', ')

    const renderDataBlock = (label, value) => {
        return (
            <div className={cx(styles.data)}>
                <div className={cx(styles.label)}>{label}</div>
                <div className={cx(styles.value)}>{value}</div>
            </div>
        );
    };
    return (
        <div>
            <div className={cx(styles.sectionTitle)}>Recipient details</div>
            <div className={cx(styles.section)}>
                <div className={cx(styles.segment)}>
                    <div className={cx(styles.details)}>
                        {recipient.type === 'business' &&
                            renderDataBlock('Business name', toCapitalize(recipient.company_name))}
                        {recipient.type === 'person' &&
                            renderDataBlock(
                                'Person name',
                                `${recipient.first_name} ${recipient.last_name}`
                            )}
                        {renderDataBlock('', '')}
                        {renderDataBlock('Nick name', recipient.nickname ?? '-')}
                        {renderDataBlock('Email', recipient.email ?? '-')}
                        <div className={cx(styles.data)} style={{ width: '75%' }}>
                            <div className={cx(styles.label)}>Residence address</div>
                            <div className={cx(styles.value)}>
                                {recipientAddressConcat ? recipientAddressConcat: '-'}
                            </div>
                        </div>
                    </div>
                    <div className={cx(styles.action)}>
                        <div className={styles.editDetails} onClick={onEdit}>
                            <CustomTooltip info={'Click here to edit recipient details'} enableInfoStyle={false}>
                                <Icon name="edit" color="#00A09B" />
                            </CustomTooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewResidenceDetails;
