import React, { useCallback } from 'react';
import { Grid } from '@mui/material';
import { InputField, RadioGroup, SelectField } from 'components/common';
import cx from 'classnames';
import styles from 'assets/styles/recipients.module.scss';
import selectCompanyState from 'store/selectors/company';
import selectGlobalState from 'store/selectors/global';
import { useFormik } from 'formik';
import { SelectCountry, SingleCurrencyAutocomplete } from 'components/common/SelectInput';
import { Country } from 'country-state-city';
import { fundsType } from './constants';
import { paymentPurposeTypes } from './types';
import { PROVIDERS } from 'utils/constants';
import * as yup from 'yup';
import { ButtonV2 } from 'components/common/Button';

const AddBankDetails = ({ defaultValues, onSubmit, onCancel }) => {
    const renderValidationSchema = (providers) => {
        return yup.lazy((values) => {
            const validationSchema = {
                account_holder_name: yup
                    .string('Full name of the recipient')
                    .required('Recipient name is a required field')
                    .uppercase(),
                type: yup.string('Type of recipient').required('Please select a type of recipient'),
                country: yup.string('Country').required('Please select the country'),
                default_purpose: yup.string('Payment purpose').nullable(),
            };
            const validationSchemaWithCurrency = {
                ...validationSchema,
                ...validationSchemaForCurrency(providers, values.currency, values),
            };
            return yup.object(validationSchemaWithCurrency);
        });
    };

    const validationSchemaForCurrency = (providers, currency, values) => {
        if (providers.length === 1 && providers[0] === PROVIDERS.MERLIN) {
            if (currency === 'GBP') {
                return {
                    account_number: yup
                        .string('Account number (8 digits)')
                        .max(8, 'Account number must be 8 digits')
                        .required('Please enter the account number'),
                    sort_code: yup
                        .string('Sort code')
                        .max(6, 'Sort code must be 6 digits')
                        .required('Please enter the sort code'),
                };
            } else if (currency === 'EUR') {
                return {
                    iban: yup
                        .string('IBAN number')
                        .required('Please enter the IBAN number')
                        .min(8, 'IBAN must be 8 characters'),
                    bic: yup.string('BIC code').min(8, 'BIC code must be 8 characters'),
                };
            }
        } else {
            return {
                account_number: yup
                    .string('Account number (8 digits)')
                    .test('isValid', 'Account details invalid.', function () {
                        if (!values.account_number) {
                            // If both root identifier not present, invalid combination
                            if (!values.iban) {
                                return false;
                            } else {
                                // IBAN validations will be done seperately
                                return true;
                            }
                        }
                        if (values.sort_code || values.bic) {
                            return true;
                        }
                        return false;
                    }),
                iban: yup
                    .string('IBAN number')
                    .test('isValid', 'Account details invalid.', function () {
                        if (!values.iban) {
                            // If both root identifier not present, invalid combination
                            if (!values.account_number) {
                                return false;
                            } else {
                                // Account number validations will be done seperately
                                return true;
                            }
                        }
                        // IBAN is a valid combination
                        return true;
                    }),
                sort_code: yup.string('Sort code'),
                bic: yup.string('BIC code'),
            };
        }
        return {};
    };

    const { providers } = selectCompanyState();
    const countries = Country.getAllCountries();
    const { enabledCurrencies } = selectGlobalState();

    const bankCountries = countries.map((country) => ({
        label: country.name,
        value: country.isoCode,
    }));

    const formik = useFormik({
        initialValues: {
            country: defaultValues?.country,
            account_holder_name: defaultValues?.account_holder_name,
            currency: defaultValues?.currency,
            type: defaultValues?.type,
            account_number: defaultValues?.account_number,
            sort_code: defaultValues?.sort_code,
            iban: defaultValues?.iban,
            bic: defaultValues?.bic,
            verification_method: 'sms_otp',
            default_purpose: defaultValues?.default_purpose,
        },
        validationSchema: renderValidationSchema(providers),
        onSubmit: (values) => {
            onSubmit(values);
        },
    });

    const updateToUpperCase = useCallback(
        (event) => {
            formik.setValues((data) => ({
                ...data,
                [event.target.name]: event.target.value?.toUpperCase(),
            }));
        },
        [formik]
    );

    const trimWhitespaces = useCallback(
        (event) => {
            // Trim the extra spaces around the string and fix the multiple spaces between string using regex
            formik.setValues((data) => ({
                ...data,
                [event.target.name]: event.target.value?.trim().replace(/\s+/g, ' '),
            }));
        },
        [formik]
    );

    const removeSpecialCharacter = useCallback(
        (event) => {
            // Replace spaces with empty string
            formik.setValues((data) => ({
                ...data,
                [event.target.name]: event.target.value?.replace(/[^0-9a-zA-Z]+/gi, ''),
            }));
        },
        [formik]
    );

    const handleChange = useCallback(
        (event) => {
            formik.setValues((data) => ({
                ...data,
                [event.target.name]: event.target.value,
            }));
        },
        [formik]
    );

    const handleSelect = (name, value) => {
        formik.setValues((data) => ({ ...data, [name]: value }));
    };

    const handleAutocompleteSelect = (name, value) => {
        formik.setValues((data) => ({ ...data, [name]: value }));
    };

    return (
        <Grid item xs={12} md={12} lg={12} sm={12}>
            <Grid item xs={12}>
                <Grid item xs={12} style={{ display: 'flex' }}>
                    <Grid item xs={6} style={{ paddingRight: '24px' }}>
                        <RadioGroup
                            options={fundsType}
                            label="I am sending funds to a"
                            required={true}
                            defaultValue={formik.values.type}
                            onChange={handleChange}
                            alignLabel={false}
                            isRow
                            name={'type'}
                            error={formik.touched.type && Boolean(formik.errors.type)}
                            helperText={formik.touched.type && formik.errors.type}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectField
                            name={'default_purpose'}
                            label={'Payment purpose'}
                            options={paymentPurposeTypes}
                            onChange={handleSelect}
                            className={cx(styles.inputField)}
                            disable={!formik.values.type}
                            value={formik.values.default_purpose}
                            error={
                                formik.touched.default_purpose &&
                                Boolean(formik.errors.default_purpose)
                            }
                            helperText={
                                formik.touched.default_purpose && formik.errors.default_purpose
                            }
                        />
                    </Grid>
                </Grid>
                <div className={cx(styles.sectionTitle)}>Enter bank account details</div>
                <Grid item xs={12} style={{ display: 'flex' }}>
                    <Grid item xs={6} style={{ paddingRight: '20px' }}>
                        <SingleCurrencyAutocomplete
                            info={'This is the country in which the bank account is created.'}
                            name={'currency'}
                            label={'Select currency'}
                            variant={'form'}
                            required={true}
                            currencies={enabledCurrencies}
                            disable={!formik.values.type}
                            handleChange={handleSelect}
                            value={formik.values.currency}
                            className={cx(styles.inputField)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputField
                            name={'account_holder_name'}
                            label={'Account holder name'}
                            className={cx(styles.inputField)}
                            disable={!formik.values.type}
                            required={true}
                            onChange={updateToUpperCase}
                            value={formik.values.account_holder_name}
                            error={
                                formik.touched.account_holder_name &&
                                Boolean(formik.errors.account_holder_name)
                            }
                            helperText={
                                formik.touched.account_holder_name &&
                                formik.errors.account_holder_name
                            }
                            onBlur={trimWhitespaces}
                        />
                    </Grid>
                </Grid>
                <div
                    style={{
                        position: 'relative',
                        bottom: '-30px',
                        background: '#fff',
                        width: 'fit-content',
                    }}>
                    Account identifiers<span className={cx(styles.asterisk)}> *</span>&nbsp;
                </div>
                <Grid
                    item
                    xs={12}
                    style={{
                        display: 'flex',
                        border: '1px solid rgb(229, 229, 229)',
                        borderRadius: '15px',
                        marginTop: '20px',
                        padding: '24px 40px 40px',
                    }}>
                    <Grid item xs={6} style={{ paddingRight: '20px' }}>
                        <InputField
                            name={'account_number'}
                            label={'Account number'}
                            className={cx(styles.inputField)}
                            disable={!formik.values.type}
                            onChange={removeSpecialCharacter}
                            value={formik.values.account_number}
                            error={
                                formik.touched.account_number &&
                                Boolean(formik.errors.account_number)
                            }
                            helperText={
                                formik.touched.account_number && formik.errors.account_number
                            }
                        />
                        <InputField
                            name={'sort_code'}
                            label={'Sort code'}
                            className={cx(styles.inputField)}
                            disable={!formik.values.type}
                            onChange={removeSpecialCharacter}
                            value={formik.values.sort_code}
                            error={formik.touched.sort_code && Boolean(formik.errors.sort_code)}
                            helperText={formik.touched.sort_code && formik.errors.sort_code}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputField
                            name={'iban'}
                            label={'IBAN'}
                            className={cx(styles.inputField)}
                            disable={!formik.values.type}
                            additionalInputProps={{
                                maxLength: 34,
                            }}
                            onChange={removeSpecialCharacter}
                            value={formik.values.iban}
                            error={formik.touched.iban && Boolean(formik.errors.iban)}
                            helperText={formik.touched.iban && formik.errors.iban}
                        />
                        <InputField
                            name={'bic'}
                            label={'BIC code'}
                            className={cx(styles.inputField)}
                            disable={!formik.values.type}
                            additionalInputProps={{
                                maxLength: 15,
                            }}
                            onChange={removeSpecialCharacter}
                            value={formik.values.bic}
                            error={formik.touched.bic && Boolean(formik.errors.bic)}
                            helperText={formik.touched.bic && formik.errors.bic}
                        />
                    </Grid>
                </Grid>
                <hr className={cx(styles.divider)} />
                <div
                    style={{
                        marginTop: '20px',
                        width: '50%',
                        paddingRight: '20px',
                        boxSizing: 'border-box',
                    }}>
                    <div className={cx(styles.sectionTitle)}>Enter bank address</div>
                    <SelectCountry
                        name="country"
                        value={formik.values.country}
                        label={'Select country'}
                        disable={!formik.values.type}
                        countries={bankCountries}
                        required={true}
                        onChange={handleAutocompleteSelect}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        padding: '16px 0px',
                        justifyContent: 'center',
                        gap: '20px',
                    }}>
                    <ButtonV2 text={'Cancel'} variant="secondary" onClick={onCancel} />

                    <ButtonV2
                        text={'Continue'}
                        onClick={formik.handleSubmit}
                        disabled={!formik.values.type || !formik.isValid}
                    />
                </div>
            </Grid>
        </Grid>
    );
};

export default AddBankDetails;
